export default function groupCategories(categories) {
    const groupedCategories = [];

    categories.forEach((category) => {
        const ids = category.id.split(".");
        const length = ids.length - 1;

        groupedCategories[length] = groupedCategories[length] || [];
        groupedCategories[length].push(category);
    });

    return groupedCategories;
}
