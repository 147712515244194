import { combineReducers } from "redux";
import categories from "../data/categories";
import dataSources from "../data/dataSources";
import context from "./context.reducer";

const initialState = {
    categories,
    subCategories: [],
    dataSources,
};

export default combineReducers({
    context,
    categories() {
        return initialState.categories;
    },
    dataSources() {
        return initialState.dataSources;
    },
});
