import PropTypes from "prop-types";
import React from "react";
import Button from "react-mdl/lib/Button";

ToggleScaleButton.propTypes = {
    onLogSelect: PropTypes.func,
    onLinSelect: PropTypes.func,
    scale: PropTypes.string,
    logDisabled: PropTypes.bool,
    vertical: PropTypes.bool,
};

function ToggleScaleButton({
    onLogSelect = Function.prototype,
    onLinSelect = Function.prototype,
    scale = "lin",
    logDisabled = false,
    vertical = false,
}) {
    return (
        <div
            className={
                vertical
                    ? "df-toggle-scale-wrapper--vertical"
                    : "df-toggle-scale-wrapper"
            }
        >
            <Button raised onClick={onLinSelect} colored={scale === "lin"}>
                <span>Lin</span>
            </Button>
            <Button
                raised
                onClick={onLogSelect}
                disabled={logDisabled}
                colored={scale === "log"}
            >
                <span>Log</span>
            </Button>
        </div>
    );
}

export default ToggleScaleButton;
