/*  eslint-disable import/max-dependencies */
// entry point to all charts has to have all these dependencies
import React from "react";
import { connect } from "react-redux";
import DataSourceComponent from "./DataSourceComponent";
import DataFilterComponent from "./DataFilterComponent";
import ImpressumComponent from "./ImpressumComponent";
import AboutComponent from "./AboutComponent";
import GenericChartPage from "../views/genericChart/GenericChartPage";
import Header from "../views/common/Header";
import Footer from "../views/common/Footer";
import DataSourceButton from "../views/dataSource/DataSourceButton";
import DataSourceDeleteButton from "../views/dataSource/DataSourceDeleteButton";
import TimeSlider from "../views/timeSlider/TimeSlider";
import AutoPlayButton from "../views/autoPlayButton/AutoPlayButton";
import ToggleScaleButton from "../views/toggleScaleButton/ToggleScaleButton";
import SourceInfoTooltip from "../views/sourceInfo/SourceInfoTooltip";
import openDataSourceModal from "../actions/context/dataSource/openDataSourceModal";
import openDataFilterModal from "../actions/context/dataFilter/openDataFilterModal";
import openImpressumModal from "../actions/context/impressum/openImpressumModal";
import openAboutModal from "../actions/context/about/openAboutModal";
import selectYear from "../actions/context/chart/selectYear";
import startAutoPlay from "../actions/context/timeSlider/startAutoPlay";
import stopAutoPlay from "../actions/context/timeSlider/stopAutoPlay";
import setTimeSliderValue from "../actions/context/timeSlider/setValue";
import selectScale from "../actions/context/chart/selectScale";
import DataFilterButton from "../views/dataFilter/DataFilterButton";
import VerticalBarComponent from "./VerticalBarComponent";
import HorizontalBarComponent from "./HorizontalBarComponent";
import LineComponent from "./LineComponent";
import RadarComponent from "./RadarComponent";
import BubbleComponent from "./BubbleComponent";
import WorldMapPageComponent from "./WorldMapComponent";
import toggleCountrySelectionWorldMapChart from "../actions/context/worldMapChart/toggleCountrySelectionWorldMapChart";
import isDataSourceSelected from "../helpers/dataSource/isDataSourceSelected";
import deleteChartContext from "../actions/context/chart/deleteChartContext";

function openDataSourceModalForAxis(axis) {
    return () => (dispatch) => dispatch(openDataSourceModal({ axis }));
}

function deleteDataSourceForYAxis() {
    return (dispatch) => {
        dispatch(deleteChartContext());
    };
}

function openDataFilterModalForChart() {
    return () => (dispatch, getState) => {
        dispatch(openDataFilterModal());
    };
}

function openImpressumModalForChart() {
    return () => (dispatch) => {
        dispatch(openImpressumModal());
    };
}

function openAboutModalForChart() {
    return () => (dispatch) => {
        dispatch(openAboutModal());
    };
}

function onYearChange() {
    return (event) => (dispatch) => {
        const year = parseInt(event.target.value);

        dispatch(selectYear({ year }));
        dispatch(setTimeSliderValue({ value: year }));
    };
}

function onScaleChange(axis, scale) {
    return () => (dispatch) => {
        dispatch(selectScale({ axis, scale }));
    };
}

function onAutoPlayStart() {
    return () => (dispatch) => dispatch(startAutoPlay());
}

function onAutoPlayStop() {
    return () => (dispatch) => dispatch(stopAutoPlay());
}

const ConnectedChartHeader = connect(
    (state) => ({
        activeTab: state.context.chart.activeTab,
        onChange: state.onChange || Function.prototype,
    }),
    {
        onClick: openAboutModalForChart(),
    }
)(Header);
const ConnectedChartFooter = connect(
    (state) => ({
        label: "Impressum",
    }),
    {
        onClick: openImpressumModalForChart(),
    }
)(Footer);
const ConnectedYDataSourceButton = connect(
    (state) => ({
        label:
            (state.context.chart.yDataSource &&
                state.context.chart.yDataSource.title) ||
            "Y-Achse",
        vertical: true,
    }),
    {
        onClick: openDataSourceModalForAxis("y"),
    }
)(DataSourceButton);
const ConnectedYDataSourceButtonBlank = connect(
    (state) => ({
        label: "Y-Achse",
        vertical: false,
    }),
    {
        onClick: openDataSourceModalForAxis("y"),
    }
)(DataSourceButton);
const ConnectedYDataSourceDeleteButton = connect((state) => ({}), {
    onClick: deleteDataSourceForYAxis,
})(DataSourceDeleteButton);
const ConnectedDataFilterButton = connect(
    (state) => ({
        children: <span>Länder/Regionen</span>,
    }),
    {
        onClick: openDataFilterModalForChart(),
    }
)(DataFilterButton);
const ConnectedVerticalBarComponent = connect((state) => ({
    data: state.context.chart.preparedData,
    xScaleRange: state.context.chart.xScaleRange,
    yScaleRange: state.context.chart.yScaleRange,
    xScale: state.context.chart.xScale,
    yScale: state.context.chart.yScale,
    xScaleTicks: state.context.chart.xScaleTicks,
    yScaleTicks: state.context.chart.yScaleTicks,
}))(VerticalBarComponent);
const ConnectedHorizontalBarComponent = connect((state) => ({
    data: state.context.chart.preparedData,
    xScaleRange: state.context.chart.xScaleRange,
    yScaleRange: state.context.chart.yScaleRange,
    xScale: state.context.chart.xScale,
    yScale: state.context.chart.yScale,
    xScaleTicks: state.context.chart.xScaleTicks,
    yScaleTicks: state.context.chart.yScaleTicks,
}))(HorizontalBarComponent);
const ConnectedLineComponent = connect((state) => ({
    data: state.context.chart.preparedData,
    xScaleRange: state.context.chart.xScaleRange,
    yScaleRange: state.context.chart.yScaleRange,
    xScale: state.context.chart.xScale,
    yScale: state.context.chart.yScale,
    xScaleTicks: state.context.chart.xScaleTicks,
    yScaleTicks: state.context.chart.yScaleTicks,
}))(LineComponent);
const ConnectedRadarComponent = connect((state) => ({
    data: state.context.chart.preparedData,
    xScaleRange: state.context.chart.xScaleRange,
    yScaleRange: state.context.chart.yScaleRange,
    xScale: state.context.chart.xScale,
    yScale: state.context.chart.yScale,
    xScaleTicks: state.context.chart.xScaleTicks,
    yScaleTicks: state.context.chart.yScaleTicks,
}))(RadarComponent);
const ConnectedBubbleComponent = connect((state) => ({
    data: state.context.chart.preparedData,
    xScaleRange: state.context.chart.xScaleRange,
    yScaleRange: state.context.chart.yScaleRange,
    xScale: state.context.chart.xScale,
    yScale: state.context.chart.yScale,
    xScaleTicks: state.context.chart.xScaleTicks,
    yScaleTicks: state.context.chart.yScaleTicks,
}))(BubbleComponent);
const ConnectedWorldMapComponent = connect(
    (state) => ({
        data: state.context.chart.preparedData,
        yScaleRange: state.context.chart.yScaleRange,
        yScale: state.context.chart.yScale,
        yScaleTicks: state.context.chart.yScaleTicks,
    }),
    {
        onFeatureClick: toggleCountrySelectionWorldMapChart,
    }
)(WorldMapPageComponent);
const ConnectedTimeSlider = connect(
    (state) => ({
        value: state.context.timeSlider.value,
        min: state.context.timeSlider.min,
        max: state.context.timeSlider.max,
    }),
    {
        onChange: onYearChange(),
    }
)(TimeSlider);
const ConnectedAutoPlayButton = connect(
    (state) => ({
        isRunning: state.context.timeSlider.isRunning,
        isDisabled: !isDataSourceSelected(state.context),
    }),
    {
        onStart: onAutoPlayStart(),
        onStop: onAutoPlayStop(),
    }
)(AutoPlayButton);
const ConnectedYToggleScaleButton = connect(
    (state) => ({
        scale: state.context.chart.yScale,
        logDisabled: state.context.chart.yScaleTicks.crossesZero,
        vertical: true,
    }),
    {
        onLogSelect: onScaleChange("y", "log"),
        onLinSelect: onScaleChange("y", "lin"),
    }
)(ToggleScaleButton);
const ConnectedYSourceInfoTooltip = connect((state) => ({
    position: "right",
    source: state.context.chart.yDataSource
        ? `Quelle: CC BY-4.0 Lizenz (WDI/World Bank) ${
              state.context.chart.yDataSource.sourceOrganization ?? ""
          }`
        : "Wähle dein Datenabenteuer",
}))(SourceInfoTooltip);
const ConnectedXToggleScaleButton = connect(
    (state) => ({
        scale: state.context.chart.xScale,
        logDisabled: state.context.chart.xScaleTicks.crossesZero,
    }),
    {
        onLogSelect: onScaleChange("x", "log"),
        onLinSelect: onScaleChange("x", "lin"),
    }
)(ToggleScaleButton);
const ConnectedXSourceInfoTooltip = connect((state) => ({
    position: "top",
    source: state.context.chart.xDataSource
        ? `Quelle: CC BY-4.0 Lizenz (WDI/World Bank) ${
              state.context.chart.xDataSource.sourceOrganization ?? ""
          }`
        : "Wähle dein Datenabenteuer",
}))(SourceInfoTooltip);
const ConnectedXDataSourceButton = connect(
    (state) => ({
        label:
            (state.context.chart.xDataSource &&
                state.context.chart.xDataSource.title) ||
            "X-Achse",
    }),
    {
        onClick: openDataSourceModalForAxis("x"),
    }
)(DataSourceButton);
const ConnectedXDataSourceButtonBlank = connect(
    (state) => ({
        label: "X-Achse",
    }),
    {
        onClick: openDataSourceModalForAxis("x"),
    }
)(DataSourceButton);

export default connect((state) => ({
    ChartHeader: ConnectedChartHeader,
    ChartFooter: ConnectedChartFooter,
    DataSourceComponent,
    DataFilterComponent,
    DataFilterButton: ConnectedDataFilterButton,
    TimeSlider: ConnectedTimeSlider,
    AutoPlayButton: ConnectedAutoPlayButton,
    XDataSourceButton: ConnectedXDataSourceButton,
    XDataSourceButtonBlank: ConnectedXDataSourceButtonBlank,
    XToggleScaleButton: ConnectedXToggleScaleButton,
    XSourceInfoTooltip: ConnectedXSourceInfoTooltip,
    YDataSourceButton: ConnectedYDataSourceButton,
    YDataSourceButtonBlank: ConnectedYDataSourceButtonBlank,
    YDataSourceDeleteButton: ConnectedYDataSourceDeleteButton,
    YToggleScaleButton: ConnectedYToggleScaleButton,
    YSourceInfoTooltip: ConnectedYSourceInfoTooltip,
    Impressum: ImpressumComponent,
    About: AboutComponent,
    ChartType: state.context.chart.chartType,
    VerticalBarPage: ConnectedVerticalBarComponent,
    HorizontalBarPage: ConnectedHorizontalBarComponent,
    LinePage: ConnectedLineComponent,
    RadarPage: ConnectedRadarComponent,
    BubblePage: ConnectedBubbleComponent,
    WorldMapPage: ConnectedWorldMapComponent,
}))(GenericChartPage);
