import { mergeState } from "../../context.actions";
import { transformIntoRadarChartFormat } from "../../../helpers/chart/transformIntoChartjsFormat";
import correlateData from "../../../helpers/radarChart/correlateDataRadarChart";
import getMinYear from "../../../helpers/chart/getMinYear";

const context = "chart";

export default function prepareRadarChartData() {
    return (dispatch, getState) => {
        const { selectedCountryIds, selectedRegionIds } =
            getState().context.dataFilter;
        const { datas, year, dataSources, chartType } =
            getState().context[context];

        if (!year || !datas || !dataSources) {
            return;
        }

        const maxYears = datas.map((data) => {
            return Math.max(...Object.keys(data));
        });
        const updatedYear =
            year > Math.max(...maxYears) ? Math.max(...maxYears) : year;

        const labels = dataSources.map((data) => data.title);
        const datasets = datas.map((data) => correlateData(data, updatedYear));
        const preparedData = {
            datasets: transformIntoRadarChartFormat({
                datasets,
                selectedCountryIds,
                selectedRegionIds,
                chartType,
            }),
            labels,
            year: updatedYear,
        };

        dispatch(
            mergeState({
                context,
                state: {
                    preparedData,
                },
            })
        );
    };
}
