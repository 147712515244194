// The data we use is from the world bank. The oldest data is from 1960.
export const minYear = 1960;
// If DATAFOVEA still exists in the year 3000 - please adjust
export const maxYear = 2021;
// Any year likely to have datasets
export const defaultYear = 2006;

// DATAFOVEABLUE used in World Map Chart
export const datafoveaBlue = "#00648C";

// Geo location of Spain used in World Map Chart to center chart
export const locationFlensburg = [41.294856, -4.055685];

// Colors of countries used in World Map Chart
export const rangeColor = [
    "#f1eef6",
    "#d7b5d8",
    "#df65b0",
    "#dd1c77",
    "#980043",
];

// Telephone number and email used in Impressum and About
export const telephoneNumber = "+49 (0) 4 61 500 80 35";
export const emailHref = "mailto:peer@egtved.de?subject=DataFovea";
export const emailText = "peer@egtved.de";
