import PropTypes from "prop-types";
import React from "react";
import Slider from "react-mdl/lib/Slider";
import { minYear } from "../../constants";

TimeSlider.propTypes = {
    onChange: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
    value: PropTypes.number,
};

function TimeSlider({
    onChange = Function.prototype,
    min = minYear,
    max = new Date().getFullYear(),
    value,
}) {
    return (
        <div className="df-time-slider">
            <span className="df-time-slider__min-label">{min}</span>
            <div className="df-time-slider__control">
                <Slider min={min} max={max} value={value} onChange={onChange} />
            </div>
            <span className="df-time-slider__max-label">{max}</span>
        </div>
    );
}

export default TimeSlider;
