import loadData from "../../../helpers/dataSource/loadData";
import exec from "../../../helpers/context/exec";
import determineYearRange from "../../../helpers/dataSource/determineYearRange";
import determineScaleTickRange from "../../../helpers/chart/determineScaleTickRange";
import setTimeSliderYearRange from "../../../actions/context/timeSlider/setRange";
import setTimeSliderValue from "../../../actions/context/timeSlider/setValue";
import selectScaleTicks from "../chart/selectYScaleTicks";
import selectScale from "../chart/selectScale";
import selectYear from "../chart/selectYear";
import prepareData from "./prepareDataBubbleChart";

const context = "chart";

export default function selectDataSourceBubbleChart({ id = "", axis = "y" }) {
    return (dispatch, getState) => {
        const { dataSources } = getState();
        const selectedDataSource = dataSources.find((d) => d.id === id) || null;
        const initialState = {
            [`${axis}DataSource`]: selectedDataSource,
        };

        exec({
            context,
            initialState,
            tasks: {
                [`${axis}Data`]() {
                    return selectedDataSource
                        ? loadData(selectedDataSource.indicatorId || id)
                        : Promise.resolve(null);
                },
            },
            dispatch(action) {
                const selectedDataSource =
                    getState().context[context][`${axis}DataSource`];

                // allow initial state to pass, otherwise the state is not changeable anymore
                if (
                    action.state === initialState ||
                    (selectedDataSource && selectedDataSource.id === id)
                ) {
                    dispatch(action);
                }
            },
            // eslint-disable-next-line complexity
        }).then(() => {
            const { xData, yData, zData, xScale, yScale } =
                getState().context[context];
            const { value } = getState().context.timeSlider;

            if (xData && yData && zData) {
                const ranges = [
                    determineYearRange(Object.keys(xData)),
                    determineYearRange(Object.keys(yData)),
                    determineYearRange(Object.keys(zData)),
                ];
                const minYear = Math.max(...ranges.map((range) => range.min));
                const maxYear = Math.min(...ranges.map((range) => range.max));
                const xScaleTicks = determineScaleTickRange(xData, minYear);
                const yScaleTicks = determineScaleTickRange(yData, minYear);
                let currentValue = value;

                if (value < minYear) {
                    currentValue = minYear;
                }
                if (value > maxYear) {
                    currentValue = maxYear;
                }

                yScaleTicks.crossesZero = false;
                xScaleTicks.crossesZero = false;

                dispatch(
                    setTimeSliderYearRange({ min: minYear, max: maxYear })
                );

                dispatch(selectScaleTicks({ axis: "x", ...xScaleTicks }));
                dispatch(selectScaleTicks({ axis: "y", ...yScaleTicks }));

                if (xScaleTicks.crossesZero && xScale === "log") {
                    dispatch(selectScale({ axis: "x", scale: "lin" }));
                }

                if (yScaleTicks.crossesZero && yScale === "log") {
                    dispatch(selectScale({ axis: "y", scale: "lin" }));
                }

                dispatch(prepareData());
                dispatch(selectYear({ year: currentValue }));
                dispatch(setTimeSliderValue({ value: currentValue }));
            }
        });
    };
}
