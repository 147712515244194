import { actionTypes } from "../actions/context.actions";
import filterErrors from "../helpers/error/filterErrors";

const initialState = {};

function handleUpdateState(state, action) {
    return {
        ...state,
        [action.context]: action.state,
    };
}

function handleMergeState(state, action) {
    return {
        ...state,
        [action.context]: {
            ...state[action.context],
            ...action.state,
        },
    };
}

function handleInitState(state, action) {
    return {
        ...state,
        [action.context]: {
            ...action.state,
            errors: null,
        },
    };
}

function handleReportError(state, action) {
    return {
        ...state,
        [action.context]: {
            ...state[action.context],
            errors: [...(state.errors || []), action.error],
        },
    };
}

function handleResolveErrors(state, action) {
    const errors = state.errors
        ? state.errors.filter(filterErrors(action))
        : [];

    return {
        ...state,
        [action.context]: {
            ...state[action.context],
            errors: errors.length === 0 ? null : errors,
        },
    };
}

function handleDestroyStates(state, action) {
    return Object.keys(state)
        .filter((component) => action.contexts.includes(component) === false)
        .reduce(
            (newState, component) => {
                newState[component] = state[component];

                return newState;
            },
            { ...initialState }
        );
}

export default function contextReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.UPDATE_STATE:
            return handleUpdateState(state, action);
        case actionTypes.MERGE_STATE:
            return handleMergeState(state, action);
        case actionTypes.DESTROY_STATES:
            return handleDestroyStates(state, action);
        case actionTypes.INIT_STATE:
            return handleInitState(state, action);
        case actionTypes.REPORT_ERROR:
            return handleReportError(state, action);
        case actionTypes.RESOLVE_ERRORS:
            return handleResolveErrors(state, action);
        default:
            return state;
    }
}
