import { mergeState } from "../../context.actions";

const context = "dataFilter";

export default function clearDataFilterSelection() {
    return (dispatch, getState) => {
        dispatch(
            mergeState({
                context,
                state: {
                    selectedCountryIds: [],
                    selectedRegionIds: [],
                    confirmedSelection: [],
                },
            })
        );
    };
}
