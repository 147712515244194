import countries from "../../data/countries";
import regions from "../../data/regions";

const iso2CodesCountries = countries.map(
    (countryObject) => countryObject.countryISO2Code
);
const iso2CodesRegions = regions.map(
    (regionObject) => regionObject.regionISO2Code
);
const iso2Codes = [...iso2CodesCountries, ...iso2CodesRegions];

/**
 * Returns a subset of yData (all data for this year)
 *
 * @param {Object} yData
 * @param {number} year - the year selected by timeSlider
 * @returns {Array}
 */

export default function correlateDataHorizontalBarChart(yData, year) {
    return (
        Object.entries(yData)
            // only data for this "year"
            .filter(([dataSetYear]) => parseInt(dataSetYear) === year)
            .map(([dataSetYear, yearData]) =>
                iso2Codes.reduce(
                    (correlatedData, countryISO2Code) => [
                        ...correlatedData,
                        {
                            x: dataSetYear,
                            // This handles missing data in the HorizontalBarChart by setting unavailable datapoints to 0
                            y: yearData[countryISO2Code] || 0,
                            countryISO2Code,
                        },
                    ],
                    []
                )
            )
            .reduce((result, yearDataset) => [...result, ...yearDataset], [])
    );
}
