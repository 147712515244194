import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import reducers from "./reducers";
import { isDev } from "./lib/env";

const initialState = {};
const middlewares = [applyMiddleware(thunk)];

if (isDev) {
    middlewares.push(applyMiddleware(createLogger()));
}
export default createStore(
    reducers,
    initialState,
    compose(
        ...middlewares
        // Use redux devtools when installed in the browser
        // @see https://github.com/zalmoxisus/redux-devtools-extension#implementation
        // window.devToolsExtension ? window.devToolsExtension() : f => f
    )
);
