export const actionTypes = {
    INIT_STATE: "data-fovea-app/CONTEXT_INIT_STATE",
    UPDATE_STATE: "data-fovea-app/CONTEXT_UPDATE_STATE",
    MERGE_STATE: "data-fovea-app/CONTEXT_MERGE_STATE",
    DESTROY_STATES: "data-fovea-app/CONTEXT_DESTROY_STATES",
    REPORT_ERROR: "data-fovea-app/CONTEXT_REPORT_ERROR",
    RESOLVE_ERRORS: "data-fovea-app/CONTEXT_RESOLVE_ERRORS",
};

export function initState({ context, state = {} }) {
    return {
        type: actionTypes.INIT_STATE,
        context,
        state,
    };
}

export function updateState({ context, state }) {
    return {
        type: actionTypes.UPDATE_STATE,
        context,
        state,
    };
}

export function mergeState({ context, state }) {
    return {
        type: actionTypes.MERGE_STATE,
        context,
        state,
    };
}

export function destroyStates({ contexts }) {
    return {
        type: actionTypes.DESTROY_STATES,
        contexts,
    };
}

export function reportError({ context, error }) {
    return {
        type: actionTypes.REPORT_ERROR,
        context,
        error,
    };
}

export function resolveErrors({ context, code, id }) {
    return {
        type: actionTypes.RESOLVE_ERRORS,
        context,
        code,
        id,
    };
}
