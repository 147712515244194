import PropTypes from "prop-types";
import React, { useState } from "react";
import { createPortal } from "react-dom";

function Tooltip({ top, left, text }) {
    return (
        <div
            style={{
                top: `${top}px`,
                left: `${left}px`,
            }}
            className="df-tooltip"
        >
            {text}
        </div>
    );
}

function Portal({ children, target }) {
    if (!target) {
        return children;
    }

    return createPortal(children, target);
}

export default function TooltipTrigger({ text = null, children }) {
    if (!text) {
        return children;
    }
    const [show, setShow] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });

    return (
        <div
            onMouseMove={(ev) =>
                setPosition({ top: ev.clientY, left: ev.clientX })
            }
            onMouseOver={() => setShow(true)}
            onMouseOut={() => setShow(false)}
            className="df-tooltip-trigger"
        >
            {show && (
                <Portal>
                    {
                        <Tooltip
                            text={text}
                            top={position.top}
                            left={position.left}
                        />
                    }
                </Portal>
            )}
            {children}
        </div>
    );
}

Tooltip.propTypes = {
    text: PropTypes.string,
    top: PropTypes.number,
    left: PropTypes.number,
};

TooltipTrigger.propTypes = {
    text: PropTypes.string,
    children: PropTypes.node.isRequired,
};
