import PropTypes from "prop-types";
import React from "react";
import { List, ListItem, ListItemContent } from "react-mdl/lib/List";

DataFilterCountryList.propTypes = {
    countries: PropTypes.array,
    onSelectCountry: PropTypes.func,
};

export default function DataFilterCountryList({
    countries = [],
    onSelectCountry = Function.prototype,
}) {
    return (
        <List className="df-data-filter-country-list">
            {countries.map((country) => (
                <ListItem
                    key={country.countryId}
                    onClick={() => onSelectCountry(country.countryId)}
                >
                    <ListItemContent>{country.nameDE}</ListItemContent>
                </ListItem>
            ))}
        </List>
    );
}
