import { intersection, difference, union } from "lodash";
import { mergeState } from "../../context.actions";
import { regionMap } from "../../../helpers/data/regions";

const context = "dataFilter";

export default function toggleCountrySelectionByRegionId(regionId) {
    return (dispatch, getState) => {
        const { selectedCountryIds } = getState().context[context];
        const countryIdsForThisRegion = regionMap[regionId].countries;
        const isCompleteIntersection =
            intersection(selectedCountryIds, countryIdsForThisRegion).length ===
            countryIdsForThisRegion.length;

        dispatch(
            mergeState({
                context,
                state: {
                    selectedCountryIds: isCompleteIntersection
                        ? difference(
                              selectedCountryIds,
                              countryIdsForThisRegion
                          )
                        : union(selectedCountryIds, countryIdsForThisRegion),
                },
            })
        );
    };
}
