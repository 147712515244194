import filterErrors from "../../helpers/error/filterErrors";
import { resolveErrors } from "../context.actions";

export default function resolveErrorsWhenPresent(args) {
    const { context, code, id } = args;

    return (dispatch, getState) => {
        const contextState = getState().context[context];
        const errors = contextState ? contextState.errors : null;
        const hasErrorsToResolve =
            errors && errors.every(filterErrors({ code, id })) === false;

        if (hasErrorsToResolve) {
            dispatch(resolveErrors(args));
        }
    };
}
