import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import initDataSourceContext from "../actions/context/dataSource/initDataSourceContext";
import initDataFilterContext from "../actions/context/dataFilter/initDataFilterContext";
import initChartContext from "../actions/context/chart/initChartContext";
import initTimeSliderContext from "../actions/context/timeSlider/initTimeSliderContext";
import disposeTimeSliderContext from "../actions/context/timeSlider/disposeTimeSliderContext";
import selectDataSource from "../actions/context/lineChart/selectDataSourceLineChart";
import GenericChartComponent from "../components/GenericChartComponent";

const initRadarChartContext = initChartContext({
    xDataSource: {
        title: "Jahr",
    },
    chartType: "radar",
    activeTab: 5,
});

class RadarRoute extends React.Component {
    componentWillMount() {
        this.props.initDataSourceContext();
        this.props.initDataFilterContext();
        this.props.initRadarChartContext();
        this.props.initTimeSliderContext();

        if (this.props.context.chart) {
            if (this.props.context.chart.xDataSource) {
                this.props.selectDataSource({
                    id: this.props.context.chart.xDataSource.id,
                });
            }

            if (this.props.context.chart.yDataSource) {
                this.props.selectDataSource({
                    id: this.props.context.chart.yDataSource.id,
                });
            }
        }
    }

    componentWillUnmount() {
        this.props.disposeTimeSliderContext();
    }

    render() {
        return <GenericChartComponent />;
    }
}

RadarRoute.propTypes = {
    initDataSourceContext: PropTypes.func.isRequired,
    initDataFilterContext: PropTypes.func.isRequired,
    initRadarChartContext: PropTypes.func.isRequired,
    initTimeSliderContext: PropTypes.func.isRequired,
    disposeTimeSliderContext: PropTypes.func.isRequired,
};

export default connect((state) => state, {
    initDataSourceContext,
    initDataFilterContext,
    initRadarChartContext,
    initTimeSliderContext,
    selectDataSource,
    disposeTimeSliderContext,
})(RadarRoute);
