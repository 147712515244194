import regions from "../../data/regions";

export const sortedRegions = sortRegions(regions);

export function isGeoRegion(region) {
    return region.type === "GEO";
}

export function compareLabelDe(regionA, regionB) {
    return regionA.nameDE.localeCompare(regionB.nameDE);
}

export function sortRegions(regions) {
    const worldRegion = regions.find((region) => region.regionId === "WLD");
    const regionsWithoutWorld = regions.filter(
        (region) => region.regionId !== "WLD"
    );

    return [
        worldRegion,
        ...regionsWithoutWorld.filter((region) => region.type === "GEO"),
        ...regionsWithoutWorld.filter((region) => region.type !== "GEO"),
    ];
}
