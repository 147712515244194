import PropTypes from "prop-types";
import React from "react";
import { Dialog, DialogTitle, DialogContent } from "react-mdl/lib/Dialog";
import initDialogPolyfill from "../../helpers/compat/initDialogPolyfill";

Modal.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    title: PropTypes.string,
    View: PropTypes.func,
    viewProps: PropTypes.object,
    onCancel: PropTypes.func,
};

function Modal({
    className = "",
    open = false,
    title = "Modal",
    View,
    viewProps,
    onCancel = Function.prototype,
}) {
    let dialogNode;

    function onRef(node) {
        if (node) {
            dialogNode = node.dialogRef;

            initDialogPolyfill(node);
            dialogNode.addEventListener("click", onDialogClick);
        } else {
            dialogNode &&
                dialogNode.removeEventListener("click", onDialogClick);
        }
    }

    function onDialogClick(event) {
        if (event.target === dialogNode) {
            onCancel();
        }
    }

    return (
        <Dialog
            ref={onRef}
            className={`df-modal ${className}`}
            open={open}
            onCancel={onCancel}
        >
            <div className="df-modal__inner">
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    {View ? <View {...viewProps} /> : ""}
                </DialogContent>
            </div>
        </Dialog>
    );
}

export default Modal;
