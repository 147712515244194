import { mergeState } from "../../context.actions";
import groupCategories from "../../../helpers/dataSource/groupCategories";
import filterCategories from "../../../helpers/dataSource/filterCategories";

const context = "dataSource";
const matchLastIdPortion = /\.[^.]+$/g;

export default function selectSubCat({ id = "" } = {}) {
    return (dispatch, getState) => {
        const { dataSources } = getState();
        const { subCategories, breadcrumbs } = getState().context.dataSource;
        // const breadcrumbs = [{ id: "", title: "Alle" }];
        const groupedCategories = groupCategories(
            filterCategories({
                categories: subCategories,
                catId: id,
                includeAlternatives: true,
            })
        );

        breadcrumbs.push(
            ...filterCategories({ categories: subCategories, catId: id })
        );

        dispatch(
            mergeState({
                context,
                state: {
                    categories: groupedCategories,
                    dataSources: dataSources.filter(
                        (d) => d.id.replace(matchLastIdPortion, "") === id
                    ),
                    subCategories: [],
                    breadcrumbs,
                },
            })
        );
    };
}
