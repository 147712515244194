import loadData from "../../../helpers/dataSource/loadData";
import exec from "../../../helpers/context/exec";
import determineYearRange from "../../../helpers/dataSource/determineYearRange";
import determineScaleTickRange from "../../../helpers/chart/determineScaleTickRange";
import setTimeSliderYearRange from "../timeSlider/setRange";
import setTimeSliderValue from "../timeSlider/setValue";
import prepareData from "./prepareDataRadarChart";
import selectYear from "../chart/selectYear";

const context = "chart";

export default function selectDataSourceRadarChart({ ids }) {
    return (dispatch, getState) => {
        const { dataSources } = getState();
        const selectedDataSources = dataSources.filter((dataSource) =>
            ids.includes(dataSource.id)
        );
        const initialState = {
            dataSources: selectedDataSources,
        };

        exec({
            context,
            initialState,
            tasks: {
                async datas() {
                    const datas = await Promise.all(
                        selectedDataSources.map((s) => loadData(s.indicatorId))
                    );

                    return datas;
                },
            },
            dispatch(action) {
                const dataSources = getState().context[context].dataSources;

                if (
                    action.state === initialState ||
                    dataSources === selectedDataSources
                ) {
                    dispatch(action);
                }
            },
        }).then(() => {
            const { datas } = getState().context[context];
            const { value } = getState().context.timeSlider;

            if (!datas) {
                return;
            }

            const dataRanges = datas.map((y) => Object.keys(y));
            const ranges = dataRanges.map((y) => [determineYearRange(y)]);
            const minYears = ranges.map((range) =>
                Math.min(...range.map((range) => range.min))
            );
            const maxYears = ranges.map((range) =>
                Math.max(...range.map((range) => range.max))
            );
            const minYear = Math.min(...minYears);
            const maxYear = Math.max(...maxYears);
            let currentValue = value;

            if (value < minYear) {
                currentValue = minYear;
            }
            if (value > maxYear) {
                currentValue = maxYear;
            }

            dispatch(setTimeSliderYearRange({ min: minYear, max: maxYear }));
            dispatch(selectYear({ year: currentValue }));
            dispatch(setTimeSliderValue({ value: currentValue }));
            dispatch(prepareData());
        });
    };
}
