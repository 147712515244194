/* eslint-disable no-invalid-this */

import PropTypes from "prop-types";
import React from "react";
import equal from "fast-deep-equal";
import { Bar } from "react-chartjs-2";
import { getDefaultChartOptions } from "../../helpers/chart/getDefaultChartOptions";
import ChartBackground from "../genericChart/ChartBackground";

function tooltipTitleCallback(value) {
    const [{ datasetIndex }] = value;
    /*
     *  TODO: Refactor data structure so that dataItem can be fetched like in `getDefaultChartOptions`:
     *
     *  const dataItem = this._data.datasets[datasetIndex].data[dataItemIndex];
     */
    const dataItem = this._data.datasets[datasetIndex];
    const regionName = this._data.datasets[datasetIndex].regionNameDE;

    return `${dataItem.nameDE}, ${regionName}`;
}

function chartOptions(yScale, yScaleTicks, xScaleTicks) {
    const chartOptions = getDefaultChartOptions(
        yScaleTicks,
        xScaleTicks,
        yScale
    );
    const yAxesTickCallback = chartOptions.scales.yAxes[0].ticks.callback;

    chartOptions.tooltips.callbacks.title = tooltipTitleCallback;

    chartOptions.scales.xAxes[0].ticks.stepSize = 10;
    chartOptions.scales.xAxes[0].ticks.min = xScaleTicks.minYear;
    chartOptions.scales.xAxes[0].ticks.max = xScaleTicks.maxYear;
    chartOptions.scales.xAxes[0].type = "category";
    chartOptions.scales.xAxes[0].stacked = true;

    chartOptions.scales.yAxes[0].stacked = true;
    // reassigning the callback to the necessary data only (Intl format for y-ticks)
    chartOptions.scales.yAxes[0].ticks = {
        callback: yAxesTickCallback,
    };

    // The chart should occupy as much space as possible
    chartOptions.maintainAspectRatio = false;

    return chartOptions;
}

class VerticalBarChart extends React.Component {
    render() {
        const { data, yScale, yScaleTicks, xScaleTicks } = this.props;
        const lastScaleAxisConfig = this.scaleAxisConfig;
        const scaleAxisConfig = [yScale, yScaleTicks, xScaleTicks];
        const redrawAxis =
            equal(lastScaleAxisConfig, scaleAxisConfig) === false;

        return (
            <div className="df-chart">
                <ChartBackground
                    currentYear={data === undefined ? null : data.year}
                />
                {data ? (
                    <Bar
                        data={data}
                        redraw={redrawAxis}
                        options={chartOptions(yScale, yScaleTicks, xScaleTicks)}
                    />
                ) : null}
            </div>
        );
    }
}

VerticalBarChart.propTypes = {
    data: PropTypes.object,
    yScale: PropTypes.string,
    yScaleTicks: PropTypes.object,
    xScaleTicks: PropTypes.object,
};

export default VerticalBarChart;
