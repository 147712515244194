/* eslint-disable no-invalid-this */

import PropTypes from "prop-types";
import React from "react";
import { HorizontalBar } from "react-chartjs-2";
import {
    getDefaultChartOptions,
    labels,
} from "../../helpers/chart/getDefaultChartOptions";
import ChartBackground from "../genericChart/ChartBackground";

function tooltipTitleCallback(value) {
    const index = value[0].index;
    const countryName = this._data.datasets[0].meta[index].nameDE;
    const regionName = this._data.datasets[0].meta[index].regionNameDE;

    return `${countryName}, ${regionName}`;
}

function tooltipLabelCallback(value) {
    return [
        `${this._data.yTitle}: ${new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 2,
        }).format(value.xLabel)}`,
    ];
}

function chartOptions(yScale, xScale, yScaleTicks, xScaleTicks) {
    // horizontal bar chart has flipped axes (x => y, y => x)
    const newYScaleTicks = xScaleTicks;
    const newXScaleTicks = yScaleTicks;
    const chartOptions = getDefaultChartOptions(
        newYScaleTicks,
        newXScaleTicks,
        yScale,
        xScale
    );

    chartOptions.tooltips.callbacks.title = tooltipTitleCallback;
    chartOptions.tooltips.callbacks.label = tooltipLabelCallback;

    chartOptions.scales.yAxes[0].ticks.stepSize = 10;
    chartOptions.scales.yAxes[0].ticks.min = xScaleTicks.minYear;
    chartOptions.scales.yAxes[0].ticks.max = xScaleTicks.maxYear;
    chartOptions.scales.yAxes[0].type = "category";
    delete chartOptions.scales.yAxes[0].ticks.callback;

    // reassigning the callback to the necessary data only (Intl format for x-ticks)
    chartOptions.scales.xAxes[0].ticks = {
        callback: labels(xScaleTicks.min),
    };

    // The chart should occupy as much space as possible
    chartOptions.maintainAspectRatio = false;

    return chartOptions;
}

class HorizontalBarChart extends React.Component {
    render() {
        const { data, yScale, xScale, yScaleTicks, xScaleTicks } = this.props;
        const barHeight = 32;
        const dataSourceCount =
            data && data.labels && data.labels.length > 0
                ? data.labels.length
                : 0;

        return (
            <div className="df-chart df-chart--horizontal-bar-chart">
                <ChartBackground currentYear={!data ? null : data.year} />
                {data ? (
                    <div
                        className="df-chart-container"
                        style={{
                            height: `${barHeight * dataSourceCount}px`,
                        }}
                    >
                        <HorizontalBar
                            data={data}
                            options={chartOptions(
                                yScale,
                                xScale,
                                yScaleTicks,
                                xScaleTicks
                            )}
                        />
                    </div>
                ) : null}
            </div>
        );
    }
}

HorizontalBarChart.propTypes = {
    data: PropTypes.object,
    yScale: PropTypes.string,
    xScale: PropTypes.string,
    yScaleTicks: PropTypes.object,
    xScaleTicks: PropTypes.object,
};

export default HorizontalBarChart;
