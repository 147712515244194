import { connect } from "react-redux";
import HorizontalBarPage from "../views/horizontalBarChart/HorizontalBarPage";
import HorizontalBarChart from "../views/horizontalBarChart/HorizontalBarChart";

const ConnectedHorizontalBarChart = connect((state) => ({
    data: state.context.chart.preparedData,
    xScaleRange: state.context.chart.xScaleRange,
    yScaleRange: state.context.chart.yScaleRange,
    xScale: state.context.chart.xScale,
    yScale: state.context.chart.yScale,
    xScaleTicks: state.context.chart.xScaleTicks,
    yScaleTicks: state.context.chart.yScaleTicks,
}))(HorizontalBarChart);

export default connect((state) => ({
    HorizontalBarChart: ConnectedHorizontalBarChart,
}))(HorizontalBarPage);
