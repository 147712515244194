import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import initDataSourceContext from "../actions/context/dataSource/initDataSourceContext";
import initDataFilterContext from "../actions/context/dataFilter/initDataFilterContext";
import initChartContext from "../actions/context/chart/initChartContext";
import initTimeSliderContext from "../actions/context/timeSlider/initTimeSliderContext";
import disposeTimeSliderContext from "../actions/context/timeSlider/disposeTimeSliderContext";
import selectDataSource from "../actions/context/bubbleChart/selectDataSourceBubbleChart";
import GenericChartComponent from "../components/GenericChartComponent";

const initBubbleChartContext = initChartContext({
    chartType: "bubble",
    activeTab: 0,
});

class BubbleRoute extends React.Component {
    componentWillMount() {
        this.props.initDataSourceContext();
        this.props.initDataFilterContext();
        this.props.initBubbleChartContext();
        this.props.initTimeSliderContext();
        // z axis is used to scale bubbles
        // id: "CAT_1.1.1" is the first category: Bevölkerung, insgesamt
        this.props.selectDataSource({ id: "CAT_1.1.1", axis: "z" });

        if (this.props.context.chart) {
            if (this.props.context.chart.xDataSource) {
                this.props.selectDataSource({
                    id: this.props.context.chart.xDataSource.id,
                    axis: "x",
                });
            }

            if (this.props.context.chart.yDataSource) {
                this.props.selectDataSource({
                    id: this.props.context.chart.yDataSource.id,
                    axis: "y",
                });
            }
        }
    }

    componentWillUnmount() {
        this.props.disposeTimeSliderContext();
    }

    render() {
        return <GenericChartComponent />;
    }
}

const dataSourceShape = PropTypes.shape({
    id: PropTypes.string,
});

BubbleRoute.propTypes = {
    initDataSourceContext: PropTypes.func.isRequired,
    initDataFilterContext: PropTypes.func.isRequired,
    initBubbleChartContext: PropTypes.func.isRequired,
    initTimeSliderContext: PropTypes.func.isRequired,
    selectDataSource: PropTypes.func.isRequired,
    disposeTimeSliderContext: PropTypes.func.isRequired,
    context: PropTypes.shape({
        chart: PropTypes.shape({
            xDataSource: dataSourceShape,
            yDataSource: dataSourceShape,
        }),
    }),
};

export default connect((state) => state, {
    initDataSourceContext,
    initDataFilterContext,
    initBubbleChartContext,
    initTimeSliderContext,
    selectDataSource,
    disposeTimeSliderContext,
})(BubbleRoute);
