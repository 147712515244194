import { initState } from "../../context.actions";
import { minYear } from "../../../constants";
import prepareBubbleChartData from "../bubbleChart/prepareDataBubbleChart";
import prepareLineChartData from "../lineChart/prepareDataLineChart";
import prepareRadarChartData from "../radarChart/prepareDataRadarChart";
import prepareVerticalBarChartData from "../verticalBarChart/prepareDataVerticalBarChart";
import prepareHorizontalBarChartData from "../horizontalBarChart/prepareDataHorizontalBarChart";
import prepareWorldMapChartData from "../worldMapChart/prepareDataWorldMapChart";

const context = "chart";
const initialState = {
    xDataSource: null,
    xData: null,
    yDataSource: null,
    yData: null,
    zDataSource: null,
    zData: null,
    dataSources: null,
    datas: null,
    year: minYear,
    xScale: "lin",
    yScale: "lin",
    defaultRegions: {},
    xScaleTicks: {},
    yScaleTicks: {},
    // chartType will be set in specific ChartRoute
    chartType: null,
};

export default function initChartContext(chartOptions = {}) {
    return () => (dispatch, getState) => {
        const initialChartState = Object.assign({}, initialState, chartOptions);
        const state = getState();
        const previousContext = state.context[context];

        if (previousContext !== undefined) {
            Object.assign(initialChartState, {
                xDataSource: previousContext.xDataSource,
                yDataSource: previousContext.yDataSource,
                zDataSource: previousContext.zDataSource,
            });
        }

        dispatch(
            initState({
                context,
                state: initialChartState,
            })
        );

        if (chartOptions.chartType === "line") {
            dispatch(prepareLineChartData());
        } else if (chartOptions.chartType === "bubble") {
            dispatch(prepareBubbleChartData());
        } else if (chartOptions.chartType === "radar") {
            dispatch(prepareRadarChartData());
        } else if (chartOptions.chartType === "verticalBar") {
            dispatch(prepareVerticalBarChartData());
        } else if (chartOptions.chartType === "horizontalBar") {
            dispatch(prepareHorizontalBarChartData());
        } else if (chartOptions.chartType === "worldMap") {
            dispatch(prepareWorldMapChartData());
        } else {
            throw new TypeError(
                `Unexpected chartType value: ${chartOptions.chartType}`
            );
        }
    };
}
