// returns true, if a modal is open

export default function isModalOpen(stateContext) {
    if (stateContext === undefined) {
        return false;
    }

    return (
        stateContext.dataFilter.modalOpen ||
        stateContext.dataSource.modalOpen ||
        (stateContext.impressum && stateContext.impressum.modalOpen) ||
        (stateContext.about && stateContext.about.modalOpen)
    );
}
