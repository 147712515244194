import PropTypes from "prop-types";
import React from "react";
import { List, ListItem, ListItemContent } from "react-mdl/lib/List";

DataFilterRegionSelection.propTypes = {
    onRegionClick: PropTypes.func,
    selectedRegions: PropTypes.array,
};

export default function DataFilterRegionSelection({
    onRegionClick = Function.prototype,
    selectedRegions = [],
}) {
    return (
        <List className="df-data-filter-region-selection">
            {selectedRegions.map((region) => (
                <ListItem
                    key={region.regionId}
                    onClick={() => onRegionClick(region.regionId)}
                >
                    <ListItemContent>
                        {region.nameDE.split("(")[0]}
                    </ListItemContent>
                </ListItem>
            ))}
        </List>
    );
}
