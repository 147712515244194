import { mergeState } from "../../context.actions";

const context = "impressum";

export default function openImpressumModal() {
    return (dispatch) => {
        dispatch(
            mergeState({
                context,
                state: {
                    modalOpen: true,
                },
            })
        );
    };
}
