import { mergeState } from "../../context.actions";
import countries from "../../../data/countries";
import regions from "../../../data/regions";
import { regionMap } from "../../../helpers/data/regions";
import countryGeoJson from "../../../data/countries-geojson";
import transformIntoLeafletFormat from "../../../helpers/worldMap/transformIntoLeafletFormat";
import correlateWorldMapData from "../../../helpers/worldMap/correlateDataWorldMapChart";
import getMinYear from "../../../helpers/chart/getMinYear";

const context = "chart";

export default function prepareWorldMapChartData() {
    return (dispatch, getState) => {
        const { selectedCountryIds } = getState().context.dataFilter;
        const { yData, year, yDataSource } = getState().context[context];

        if (!year || !yData) {
            return;
        }
        const updatedYear =
            year > Math.max(...Object.keys(yData))
                ? Math.max(...Object.keys(yData))
                : year;

        const datasets = correlateWorldMapData(yData, updatedYear, countries);
        const preparedDatasets = transformIntoLeafletFormat({
            datasets,
            regionMap,
        });
        // include countries without values into the result set
        const preparedSelectedCountries = selectedCountryIds.map(
            (sc) => preparedDatasets[sc] || sc
        );
        const preparedData = {
            datasets: preparedDatasets,
            year: updatedYear,
            yTitle: yDataSource.title,
            countryGeoJson,
            selectedCountries: preparedSelectedCountries,
            regions,
        };

        dispatch(
            mergeState({
                context,
                state: {
                    preparedData,
                },
            })
        );
    };
}
