import { mergeState } from "../../context.actions";

const context = "dataFilter";

export default function toggleCountrySelection(countryId) {
    return (dispatch, getState) => {
        const { selectedCountryIds } = getState().context[context];

        dispatch(
            mergeState({
                context,
                state: {
                    selectedCountryIds: selectedCountryIds.includes(countryId)
                        ? selectedCountryIds.filter((id) => id !== countryId)
                        : selectedCountryIds.concat(countryId),
                },
            })
        );
    };
}
