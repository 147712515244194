import { mergeState } from "../../context.actions";
import closeDataFilterModal from "./closeDataFilterModal";
import prepareBubbleChartData from "../bubbleChart/prepareDataBubbleChart";
import prepareLineChartData from "../lineChart/prepareDataLineChart";
import prepareRadarChartData from "../radarChart/prepareDataRadarChart";
import prepareVerticalBarChartData from "../verticalBarChart/prepareDataVerticalBarChart";
import prepareHorizontalBarChartData from "../horizontalBarChart/prepareDataHorizontalBarChart";
import prepareWorldMapChartData from "../worldMapChart/prepareDataWorldMapChart";

const context = "dataFilter";

export default function confirmDataFilterSelection() {
    return (dispatch, getState) => {
        const { selectedCountryIds, selectedRegionIds } =
            getState().context[context];
        const { chartType } = getState().context.chart;

        dispatch(closeDataFilterModal());
        dispatch(
            mergeState({
                context,
                state: {
                    countryIds: selectedCountryIds,
                    searchTerm: "",
                    confirmedSelection: [
                        ...selectedCountryIds,
                        ...selectedRegionIds,
                    ],
                },
            })
        );

        if (chartType === "line") {
            dispatch(prepareLineChartData());
        } else if (chartType === "bubble") {
            dispatch(prepareBubbleChartData());
        } else if (chartType === "radar") {
            dispatch(prepareRadarChartData());
        } else if (chartType === "verticalBar") {
            dispatch(prepareVerticalBarChartData());
        } else if (chartType === "horizontalBar") {
            dispatch(prepareHorizontalBarChartData());
        } else if (chartType === "worldMap") {
            dispatch(prepareWorldMapChartData());
        } else {
            throw new TypeError(`Unexpected chartType value: ${chartType}`);
        }
    };
}
