import { connect } from "react-redux";
import Modal from "../views/common/Modal";
import closeAboutModal from "../actions/context/about/closeAboutModal";
import DataFilterButton from "../views/dataFilter/DataFilterButton";
import About from "../views/common/About";

const ConnectedAboutCancelButton = connect(
    (state) => ({
        children: "Schließen",
        raised: true,
        ripple: true,
    }),
    {
        onClick: closeAboutModal,
    }
)(DataFilterButton);
const ConnectedAbout = connect((state) => ({
    AboutCancelButton: ConnectedAboutCancelButton,
}))(About);

export default connect(
    (state) => {
        const modalOpen = state.context.about
            ? state.context.about.modalOpen
            : false;

        return {
            className: "df-about-modal",
            open: modalOpen,
            title: "",
            View: modalOpen ? ConnectedAbout : null,
        };
    },
    {
        onCancel: closeAboutModal,
    }
)(Modal);
