import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from "react-router-dom";
import AppLayout from "../views/layout/AppLayout";
import BubbleRoute from "./BubbleRoute";
import LineRoute from "./LineRoute";
import RadarRoute from "./RadarRoute";
import VerticalBarRoute from "./VerticalBarRoute";
import HorizontalBarRoute from "./HorizontalBarRoute";
import WorldMapRoute from "./WorldMapRoute";

export default function Routes() {
    return (
        <Router>
            <AppLayout>
                <Switch>
                    <Route path="/bubble" component={BubbleRoute} />
                    <Route path="/säulen" component={VerticalBarRoute} />
                    <Route path="/balken" component={HorizontalBarRoute} />
                    <Route path="/linie" component={LineRoute} />
                    <Route path="/weltkarte" component={WorldMapRoute} />
                    <Route path="/radar" component={RadarRoute} />
                    <Route path="/" render={() => <Redirect to="/bubble" />} />
                </Switch>
            </AppLayout>
        </Router>
    );
}
