import { mergeState } from "../../actions/context.actions";
import resolveErrorsWhenPresent from "../../actions/context/resolveErrorsWhenPresent";
import taskStates from "./taskStates";
import commonErrorHandler from "../error/commonErrorHandler";

function noop() {}

export default function exec({
    initialState = {},
    context,
    tasks,
    dispatch = noop,
}) {
    const taskPromises = Object.keys(tasks)
        .map(
            (taskName) =>
                function task() {
                    const promise = new Promise((resolve) =>
                        resolve(tasks[taskName]())
                    );
                    const taskId = `${context}/${taskName}`;
                    const taskPending = `${taskName}Pending`;
                    const taskPromise = `${taskName}Promise`;
                    const taskState = `${taskName}State`;
                    const taskStateTimestamp = `${taskName}StateTimestamp`;

                    initialState[taskPending] = true;
                    initialState[taskPromise] = promise;
                    initialState[taskState] = taskStates.PENDING;
                    initialState[taskStateTimestamp] = Date.now();

                    return promise
                        .then((result) => {
                            dispatch(
                                mergeState({
                                    context,
                                    state: {
                                        [taskPending]: false,
                                        [taskName]: result,
                                        [taskState]: taskStates.DONE,
                                        [taskStateTimestamp]: Date.now(),
                                    },
                                })
                            );
                            dispatch(
                                resolveErrorsWhenPresent({
                                    context,
                                    id: taskId,
                                })
                            );
                        })
                        .catch((error) =>
                            commonErrorHandler({ dispatch, error })
                                // If we recovered from this error, we re-try the task
                                .then(() => task())
                        );
                    /*
                    //TODO improve error handling
                    .catch(error => {
                        error.id = taskId;
                        dispatch(reportError({
                            context,
                            error
                        }));
                        dispatch(mergeState({
                            context,
                            state: {
                                [taskPending]: false,
                                [taskState]: taskStates.ERROR,
                                [taskStateTimestamp]: Date.now()
                            }
                        }));
                    });
                    */
                }
        )
        // Start actual execution
        .map((task) => task());

    // Dispatch initial state after execution has been initialized
    dispatch(
        mergeState({
            context,
            state: initialState,
        })
    );

    return Promise.all(taskPromises);
}
