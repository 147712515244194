import { connect } from "react-redux";
import LinePage from "../views/lineChart/LinePage";
import LineChart from "../views/lineChart/LineChart";

const ConnectedLineChart = connect((state) => ({
    data: state.context.chart.preparedData,
    xScaleRange: state.context.chart.xScaleRange,
    yScaleRange: state.context.chart.yScaleRange,
    xScale: state.context.chart.xScale,
    yScale: state.context.chart.yScale,
    xScaleTicks: state.context.chart.xScaleTicks,
    yScaleTicks: state.context.chart.yScaleTicks,
}))(LineChart);

export default connect((state) => ({
    LineChart: ConnectedLineChart,
}))(LinePage);
