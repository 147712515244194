import { mergeState } from "../../context.actions";
import selectYear from "../chart/selectYear";
import setTimeSliderValue from "./setValue";
import stopAutoPlay from "./stopAutoPlay";
import isDataSourceSelected from "../../../helpers/dataSource/isDataSourceSelected";
import isModalOpen from "../../../helpers/timeSlider/isModalOpen";

const context = "timeSlider";
const AUTO_PLAY_INTERVAL = 500;

export default function startAutoPlay() {
    return (dispatch, getState) => {
        function dispatchActions() {
            const { max, value, min } = getState().context[context];
            let newYear = null;

            // reset in case of start with max selected
            if (value === max) {
                newYear = min;
            }

            newYear = newYear || value + 1;

            dispatch(selectYear({ year: newYear }));

            dispatch(setTimeSliderValue({ value: newYear }));

            if (newYear === max) {
                dispatch(stopAutoPlay());
            }
        }

        const stateContext = getState().context;

        // in case a modal is open or no source is yet selected: don't dispatch anything
        if (isModalOpen(stateContext) || !isDataSourceSelected(stateContext)) {
            return;
        }
        const autoPlayInterval = setInterval(
            dispatchActions,
            AUTO_PLAY_INTERVAL
        );

        dispatchActions();
        dispatch(
            mergeState({
                context,
                state: {
                    isRunning: true,
                    autoPlayInterval,
                },
            })
        );
    };
}
