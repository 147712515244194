import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Grid from "react-mdl/lib/Grid/Grid";
import Cell from "react-mdl/lib/Grid/Cell";
import { Link } from "react-router-dom";
import Button from "react-mdl/lib/Button";

// Manually extracted css class names since tab bars do not support <a> tags
const TAB_BAR_CLASS_NAME =
    "df-chart-header__tab-bar mdl-tabs mdl-js-tabs mdl-js-ripple-effect mdl-js-ripple-effect--ignore-events is-upgraded";

function getTabClass(activeTab, currentIndex) {
    return "mdl-tabs__tab" + (activeTab === currentIndex ? " is-active" : "");
}

Header.propTypes = {
    activeTab: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
};

function Header({ activeTab, onClick }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = (isOpen) => {
        const el = document.querySelector(".df-chart-header__tabs");
        if (isOpen) {
            el.style.display = "block";
        } else {
            el.style.display = "none";
        }
    };

    useEffect(() => {
        toggleMenu(isOpen);
    });

    return (
        <>
            <h1>2.</h1>
            <Cell>
                <Button
                    onClick={() => {
                        setIsOpen(!isOpen);
                        toggleMenu(isOpen);
                    }}
                    raised
                    ripple
                >
                    Diagrammtyp
                </Button>
                <nav className={`${TAB_BAR_CLASS_NAME} df-chart-header__tabs`}>
                    <div onClick={() => setIsOpen(false)}>
                        <Link
                            to="/bubble"
                            className={getTabClass(activeTab, 0)}
                        >
                            Bubble
                        </Link>
                    </div>
                    <div onClick={() => setIsOpen(false)}>
                        <Link
                            to="/säulen"
                            className={getTabClass(activeTab, 1)}
                        >
                            Säulen
                        </Link>
                    </div>
                    <div onClick={() => setIsOpen(false)}>
                        <Link
                            to="/balken"
                            className={getTabClass(activeTab, 2)}
                        >
                            Balken
                        </Link>
                    </div>
                    <div onClick={() => setIsOpen(false)}>
                        <Link to="/linie" className={getTabClass(activeTab, 3)}>
                            Linie
                        </Link>
                    </div>
                    <div onClick={() => setIsOpen(false)}>
                        <Link
                            to="/weltkarte"
                            className={getTabClass(activeTab, 4)}
                        >
                            Weltkarte
                        </Link>
                    </div>
                    <div onClick={() => setIsOpen(false)}>
                        <Link to="/radar" className={getTabClass(activeTab, 5)}>
                            Radar
                        </Link>
                    </div>
                    <div onClick={() => setIsOpen(false)}>
                        <a
                            className={`${getTabClass(
                                activeTab
                            )} df-chart-header__about `}
                            onClick={onClick}
                        >
                            Über DATAFOVEA
                        </a>
                    </div>
                </nav>
            </Cell>
        </>
    );
}

export default Header;
