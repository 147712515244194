import selectDataSourceBubbleChart from "../bubbleChart/selectDataSourceBubbleChart";
import closeDataSourceModal from "./closeDataSourceModal";
import selectDataSourceLineChart from "../lineChart/selectDataSourceLineChart";
import selectDataSourceRadarChart from "../radarChart/selectDataSourceRadarChart";
import selectDataSourceVerticalBarChart from "../verticalBarChart/selectDataSourceVerticalBarChart";
import selectDataSourceHorizontalBarChart from "../horizontalBarChart/selectDataSourceHorizontalBarChart";
import selectDataSourceWorldMapChart from "../worldMapChart/selectDataSourceWorldMapChart";
import { mergeState } from "../../context.actions";

const context = "dataSource";

export default function selectDataSource({ ids }) {
    return (dispatch, getState) => {
        const { axis } = getState().context[context];
        const { chartType } = getState().context.chart;

        dispatch(
            mergeState({
                context,
                state: {
                    selectedDataSourceIds: ids,
                },
            })
        );

        dispatch(closeDataSourceModal());

        if (chartType === "line") {
            dispatch(
                selectDataSourceLineChart({
                    id: ids[0],
                })
            );
        } else if (chartType === "bubble") {
            dispatch(
                selectDataSourceBubbleChart({
                    axis,
                    id: ids[0],
                })
            );
        } else if (chartType === "verticalBar") {
            dispatch(
                selectDataSourceVerticalBarChart({
                    id: ids[0],
                })
            );
        } else if (chartType === "horizontalBar") {
            dispatch(
                selectDataSourceHorizontalBarChart({
                    id: ids[0],
                })
            );
        } else if (chartType === "radar") {
            dispatch(
                selectDataSourceRadarChart({
                    ids,
                })
            );
        } else if (chartType === "worldMap") {
            dispatch(
                selectDataSourceWorldMapChart({
                    id: ids[0],
                })
            );
        } else {
            throw new TypeError(`Unexpected chartType value: ${chartType}`);
        }
    };
}
