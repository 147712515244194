import { mergeState } from "../../context.actions";

const context = "dataSource";

export default function closeDataSourceModal() {
    return (dispatch) => {
        dispatch(
            mergeState({
                context,
                state: {
                    modalOpen: false,
                },
            })
        );
    };
}
