/* eslint-disable max-len */
/* eslint-disable react/jsx-indent */
import PropTypes from "prop-types";
import React from "react";

ChartBackground.propTypes = {
    currentYear: PropTypes.number,
};

export default function ChartBackground({ currentYear }) {
    return (
        <>
            {!currentYear && (
                <div className="df-chart__instructions">
                    Wähle dein Datenabenteuer
                    <ul>
                        <li>1. Wähle Länder und/oder Regionen</li>
                        <li>2. Wähle deinen Diagrammtyp</li>
                        <li>3. Wähle deinen Datensatz</li>
                        <li>
                            4. Die Daten stammen von der World Bank und stehen
                            unter der CC BY-4.0 Lizenz
                        </li>
                    </ul>
                </div>
            )}
            {currentYear && (
                <div className="df-chart__background-wrapper">
                    <div className="df-chart__background">{currentYear}</div>
                    <div className="df-chart__copyright">
                        {currentYear ? "DATAFOVEA" : ""}
                    </div>
                </div>
            )}
        </>
    );
}
