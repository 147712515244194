import { mergeState } from "../../context.actions";

const context = "chart";

export default function selectYScaleTicks({
    axis = "y",
    min = 0,
    max = 10000,
    minNotZero,
    crossesZero,
}) {
    return (dispatch) => {
        dispatch(
            mergeState({
                context,
                state: {
                    [`${axis}ScaleTicks`]: {
                        min,
                        max,
                        minNotZero,
                        crossesZero,
                    },
                },
            })
        );
    };
}
