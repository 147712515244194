import PropTypes from "prop-types";
import React from "react";

VerticalBarPage.propTypes = {
    VerticalBarChart: PropTypes.func.isRequired,
};

function VerticalBarPage({ VerticalBarChart }) {
    return (
        <div>
            <VerticalBarChart />
        </div>
    );
}

export default VerticalBarPage;
