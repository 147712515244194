import PropTypes from "prop-types";
import React from "react";
import Button from "react-mdl/lib/Button";

function createPropsWithoutChildren(props) {
    const newProps = { ...props };

    delete newProps.children;

    return newProps;
}

MultilineButton.propTypes = {
    children: PropTypes.arrayOf(PropTypes.node),
};

export default function MultilineButton(props) {
    const propsWithoutChildren = createPropsWithoutChildren(props);
    const className = propsWithoutChildren.className || "";

    propsWithoutChildren.className = className + " df-multiline-button";

    return <Button {...propsWithoutChildren}>{props.children}</Button>;
}
