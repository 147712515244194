import { mergeState } from "../../context.actions";
import scaleRadiusData from "../../../helpers/bubbleChart/scaleRadiusDataBubbleChart";
import transformIntoChartjsFormat from "../../../helpers/chart/transformIntoChartjsFormat";
import correlateData from "../../../helpers/bubbleChart/correlateDataBubbleChart";
import determineYearRange from "../../../helpers/dataSource/determineYearRange";

const context = "chart";

export default function prepareBubbleChartData() {
    // eslint-disable-next-line complexity
    return (dispatch, getState) => {
        const { selectedCountryIds, selectedRegionIds } =
            getState().context.dataFilter;
        const {
            xData,
            yData,
            zData,
            xDataSource,
            yDataSource,
            chartType,
            xScale,
            yScale,
            year,
        } = getState().context[context];
        let maxYear = 0;

        if (xData && yData && zData) {
            const ranges = [
                determineYearRange(Object.keys(xData)),
                determineYearRange(Object.keys(yData)),
                determineYearRange(Object.keys(zData)),
            ];

            maxYear = Math.max(...ranges.map((range) => range.min));
        }

        if (year >= maxYear) {
            if (
                !year ||
                !xData ||
                !xData[year] ||
                !yData ||
                !yData[year] ||
                !zData ||
                !zData[year]
            ) {
                return;
            }
            const updatedYear =
                year > Math.max(...Object.keys(yData))
                    ? Math.max(...Object.keys(yData))
                    : year;
            const scaledRadiusData = scaleRadiusData(zData[updatedYear]);
            const datasets = correlateData(
                xData[updatedYear],
                yData[updatedYear],
                scaledRadiusData
            );
            const preparedData = {
                datasets: transformIntoChartjsFormat({
                    datasets,
                    selectedCountryIds,
                    selectedRegionIds,
                    chartType,
                    xScale,
                    yScale,
                }),
                year: updatedYear,
                xTitle: xDataSource.title,
                yTitle: yDataSource.title,
            };

            dispatch(
                mergeState({
                    context,
                    state: {
                        preparedData,
                    },
                })
            );
        }
    };
}
