/* eslint-disable max-lines*/
// length of the file is due to textual content, not bad coding style

import React from "react";
import PropTypes from "prop-types";
import { emailHref, emailText, telephoneNumber } from "../../constants";

About.propTypes = {
    AboutCancelButton: PropTypes.func,
};

export default function About({ AboutCancelButton }) {
    return (
        <div href="/" className="df-about-modal">
            <div className="df-about-modal__text">
                <h3>Inhalt</h3>
                <p>
                    <a href="#überDatafovea">Über DATAFOVEA</a>
                    <br />
                    <a href="#WirtschaftUndPolitik">Wirtschaft und Politik</a>
                    <br />
                    <a href="#Mathematik">Mathematik</a>
                    <br />
                    <a href="#Geografie">Geografie</a>
                    <br />
                    <a href="#Anforderungsbereiche">Anforderungsbereiche</a>
                    <br />
                    <a href="#Unterricht">
                        Unterricht an allgemeinbildende und berufliche Schulen
                    </a>
                </p>
                <hr />
                <h3 id="überDatafovea">Über DATAFOVEA</h3>
                <p>
                    Digitaler Unterricht mit offenen Daten <br />
                    Mit digitalen und offenen Daten unterrichten und sich dabei
                    an der Realität orientieren. Schülerinnen und Schüler machen
                    sich durch digitale und offene Daten ein eigenes Bild von
                    der Welt und interpretieren diese möglicherweise neu. In den
                    Fächern Mathematik, Geografie sowie Politik und Wirtschaft
                    ist dieses durch Nutzung von offenen Daten möglich. Die
                    Daten auf DATAFOVEA stammen aus unterschiedlichen Quellen,
                    in erster Linie handelt es sich jedoch um Daten der
                    Weltbank. Wider Fake News – hin zur eigenen Urteils- und
                    Handlungsfähigkeit, das sind die fachdidaktischen Ziele des
                    Projektes von DATAFOVEA.
                </p>
                <p>
                    DATAFOVEA: <br />
                    Mit der eigenen Datenanalyse sieht man schärfer (FOVEA ist
                    der Punkt des schärfsten Sehens im eigenen Auge).
                </p>
                <p>
                    Sie interessieren sich für digitalen Unterricht mit offenen
                    Daten? <br />
                    Sprechen Sie uns unverbindlich an:
                </p>
                <p>
                    DATAFOVEA GmbH <br />
                    Redaktion: Dr. Peer Egtved <br />
                    Telefon {telephoneNumber} <br />
                    E-Mail <a href={emailHref}>{emailText}</a>
                </p>
                <hr />
                <h3 id="WirtschaftUndPolitik">Wirtschaft und Politik</h3>
                <p>
                    Mittels der Nutzung von realen und offenen Daten kann sich
                    der Wirtschafts- und Politikunterricht an existierenden
                    Problemen und Themen orientieren und entwickeln.
                    Beispielsweise:
                </p>
                <u>Ökonomie und Politik/Grundlagen</u>
                <p>
                    Die Schülerinnen und Schüler analysieren Volkswirtschaften
                    unter Berücksichtigung von ökonomischen, politischen und
                    geografischen Besonderheiten und außenwirtschaftlichen
                    Verflechtungen.
                </p>
                <ul>
                    <li>
                        Themengebiete wären u.a.: Geografie, Demografie,
                        Regierungssystem, Wirtschafts- und Finanzindikatoren,
                        Industriezweige, Konjunktur, Sektoren, Außenwirtschaft
                    </li>
                </ul>
                <u>Ziele und Grundsätze der Vereinten Nationen</u>
                <p>
                    Die Schülerinnen und Schüler setzen sich mit ausgewählten
                    Zielen der Vereinten Nationen auseinander.
                </p>
                <ul>
                    <li>
                        Millenniums-Entwicklungsziele, Agenda 2030: 17 Ziele für
                        nachhaltige Entwicklung, Good Governance-Kriterien
                    </li>
                </ul>
                <u>
                    Die europäische- bzw. die weltweiten ökonomischen Regionen
                    und Entwicklungen im Vergleich{" "}
                </u>
                <p>
                    Die Schülerinnen und Schüler setzen sich mit den
                    ökonomischen Entwicklungen anhand unterschiedlicher
                    Kennziffern in unterschiedliche Ländern und Regionen
                    auseinander.
                </p>
                <ul>
                    <li>
                        Europäische Union, OECD, Euro-Region, USA, China,
                        Sub-Sahara, Nordafrika, Ostasien, Südamerika, Failing
                        States, niedrig entwickelte Staaten
                    </li>
                </ul>
                <u>Die EU - gestern, heute, morgen</u>
                <p>
                    Die Schülerinnen und Schüler setzen sich mit den politischen
                    und ökonomischen Entwicklungen der Europäischen Union
                    auseinander.
                </p>
                <ul>
                    <li>
                        Etappen der europäischen Integration, EU-Vertrag (von
                        Maastricht), EU als Wirtschaftsmacht: Haushalts-,
                        Finanz- und Strukturpolitik, wirtschaftliche, soziale
                        und demografische Entwicklungen und Kennziffern
                    </li>
                </ul>
                <u>Nord-Süd-Konflikte und Interdependenzen</u>
                <p>
                    Die Schülerinnen und Schüler setzen sich mit dem
                    Nord-Süd-Konflikt auseinander.
                </p>
                <ul>
                    <li>
                        Nord-Süd-Konflikte sowie mögliche Interdependenzen
                        anhand von Länderbeispielen, globale Entwicklungsbanken
                        und -systeme (WTO, Weltbank, IWF, usw.),
                        Welthandelsstrukturen, Agenda 21.
                    </li>
                </ul>
                <u>
                    Internationale Beziehungen und Globalisierung sowie
                    Migration
                </u>
                <p>
                    Die Schülerinnen und Schüler setzen sich mit den
                    Internationale Beziehungen und Globalisierung auseinander.
                </p>
                <ul>
                    <li>
                        Weltwirtschaft und Globalisierung, Entwicklungs- und
                        Schwellenländer und ihre wirtschaftlichen und
                        politischen Entwicklungen und Beziehungen,
                        internationale Konfliktregionen und die Möglichkeiten
                        kollektiver Friedenssicherung, Push und Pull-Faktoren
                        der Migration
                    </li>
                </ul>
                <u>Außenhandel in einer globalisierten Welt</u>
                <p>
                    Die Schülerinnen und Schüler erfassen anhand der
                    Zahlungsbilanzen ausgewählter Länder Ausmaß und Bedeutung
                    der internationalen Güter- und Kapitalströme.
                </p>
                <ul>
                    <li>
                        Themengebiete wären: Bedeutung der Außenwirtschaft für
                        Deutschland, Zahlungsbilanz, Welthandels-und
                        Kapitalströme, Instrumente der Außenwirtschaftspolitik
                    </li>
                </ul>
                <u>
                    Spannungsverhältnis zwischen Ökonomie und Ökologie –
                    Umweltpolitik
                </u>
                <p>
                    Die Schülerinnen und Schüler setzen sich mit
                    Spannungsverhältnis von Ökonomie und Ökologie auseinander
                    und vergleichen dabei unterschiedliche Länder miteinander.
                </p>
                <ul>
                    <li>
                        Grenzen des Wachstums, Ausmaß und Ursachen der
                        Umweltprobleme, Ziele und Prinzipien der Umweltpolitik,
                        aktuelle Entwicklungen und Probleme der Klimapolitik
                    </li>
                </ul>
                <u>
                    Sozial- und Verteilungspolitik im internationalen Vergleich
                </u>
                <p>
                    Die Schülerinnen und Schüler setzen sich mit der Entwicklung
                    der Einkommens- und Vermögensverteilung auseinander und
                    vergleichen dabei unterschiedliche Länder miteinander.
                </p>
                <ul>
                    <li>
                        Messung der Einkommens- und Vermögensverteilung,
                        Veränderung der Einkommensverteilung durch
                        technologischen Wandel sowie politische Folgen des
                        Wandels, Ziele und Maßnahmen der Sozial- und
                        Verteilungspolitik, Grenzen des Sozialstaats
                    </li>
                </ul>
                <u>Wirtschaftspolitik</u>
                <p>
                    Die Schülerinnen und Schüler vergleichen konjunkturelle
                    Entwicklungen in ausgewählten Ländern mit einem
                    idealtypischen Konjunkturzyklus.
                </p>
                <ul>
                    <li>
                        Wirtschaftspolitische Ziele und Konjunkturelle
                        Schwankungen
                    </li>
                </ul>
                <u>Geldtheorie und Geldpolitik</u>
                <p>
                    Die Schülerinnen und Schüler setzen sich mit dem Aufbau und
                    den Zielen des Europäischen Systems der Zentralbanken (ESZB)
                    und der Europäischen Zentralbank (EZB) auseinander.
                </p>
                <ul>
                    <li>
                        Europäische Währungsunion (EWU), Europäisches System der
                        Zentralbanken (ESZB) und Europäische Zentralbank (EZB)
                        als Träger der Geldpolitik. Binnenwert des Geldes.
                    </li>
                </ul>
                <u>Beschäftigungs- und Arbeitsmarktpolitik in Europa</u>
                <p>
                    Die Schülerinnen und Schüler setzen sich mit der Entwicklung
                    der Arbeitsmärkte auseinander und vergleichen dabei
                    unterschiedliche Länder miteinander.
                </p>
                <p>
                    Die Schülerinnen und Schüler setzen sich mit der Entwicklung
                    der Arbeitsmärkte auseinander und vergleichen dabei
                    unterschiedliche Länder miteinander.
                </p>

                <hr />
                <h3 id="Mathematik">Mathematik</h3>
                <p>
                    Mittels der Nutzung von realen und offenen Daten kann sich
                    der Mathematikunterricht an existierenden Problemen und
                    Themen orientieren und entwickeln. Beispielsweise:
                </p>
                <ul>
                    <li>Grundrechenarten</li>

                    <li>Prozentrechnung</li>

                    <li>Bruchrechnung</li>

                    <li>Lineare Gleichungen</li>

                    <li>Statistik</li>
                </ul>
                <u>Mathematische Leitideen realisieren</u>
                <ul>
                    <li>Leitidee Zahl – Variable – Operation</li>

                    <li>Leitidee Messen</li>

                    <li>Leitidee Funktionaler Zusammenhang</li>

                    <li>Leitidee Daten und Zufall</li>
                </ul>

                <u>Mathematisch Argumentieren und Beweisen</u>
                <ul>
                    <li>
                        Die Schülerinnen und Schüler können Fragen stellen und
                        Vermutungen begründet äußern,
                    </li>

                    <li>mathematische Argumentationsstrukturen nutzen,</li>

                    <li>
                        mathematische Argumentationen, wie Erläuterungen,
                        Begründungen, Beweise, nachvollziehen und entwickeln.
                    </li>
                </ul>

                <u>Mathematisch Modellieren</u>
                <ul>
                    <li>
                        Die Schülerinnen und Schüler können Realsituationen
                        analysieren und aufbereiten,
                    </li>

                    <li>mathematisieren,</li>

                    <li>im mathematischen Modell arbeiten,</li>

                    <li>interpretieren und validieren.</li>
                </ul>

                <u>Mathematik - Prozessbezogene Kompetenzen</u>
                <ul>
                    <li>
                        Die Schülerinnen und Schüler können Probleme
                        analysieren,
                    </li>

                    <li>
                        Strategien zum Problemlösen auswählen, anwenden und
                        daraus einen Plan zur Lösung entwickeln,
                    </li>

                    <li>
                        die Lösung überprüfen und den Lösungsprozess
                        reflektieren.
                    </li>
                </ul>
                <hr />
                <h3 id="Geografie">Geografie</h3>
                <p>
                    Mittels der Nutzen und realen und offenen Daten kann sich
                    der Geografie- und Wirtschaftsgeografie-Unterricht an
                    existierenden Problemen und Themen orientieren und
                    entwickeln. Beispielsweise:
                </p>
                <u>
                    Globale Disparitäten: Entwicklungsländer und Industrieländer
                </u>
                <ul>
                    <li>
                        Indices: BSP pro Kopf, HDI (Human Development Index),
                        Gesundheit, Bildung, Geschlecht, etc.{" "}
                    </li>

                    <li>
                        Probleme der Entwicklungsländer und Industrieländer
                        sowie die Lösungsansätze{" "}
                    </li>

                    <li>
                        Tragfähigkeit der Erde (Theorie von Thomas R. Malthus){" "}
                    </li>
                </ul>
                <u>Entwicklungsstrategien und Entwicklungstheorien</u>
                <ul>
                    <li>Geodeterminismus-Theorie</li>

                    <li>Modernisierungstheorie</li>

                    <li>Dependenztheorie (endogene und exogene Faktoren)</li>

                    <li>Entwicklungszusammenarbeit</li>

                    <li>Nachhaltige Entwicklung: Dreieck der Nachhaltigkeit</li>

                    <li>Gender</li>
                </ul>

                <u>
                    Modell des demographischen Übergangs: Bevölkerungspyramiden
                    im Zusammenhang mit dem demographischen Übergang
                </u>

                <u>Verstädterung, Urbanisierung und Metropolisierung</u>
                <ul>
                    <li>Land-Stadt-Wanderung</li>

                    <li>Push-Faktoren und Pull Faktoren</li>
                </ul>
                <u>Weltweite Migration</u>
                <ul>
                    <li>Flucht, Asyl und Arbeitsmigration</li>

                    <li>Push-Faktoren und Pull Faktoren</li>
                </ul>

                <u>Weltwirtschaftliche Verflechtungen und Globalisierung</u>
                <ul>
                    <li>Vorteile und Nachteile sowie Gewinner und Verlierer</li>

                    <li>
                        Chronologie der zunehmenden Verflechtungen
                        weltwirtschaftlicher Beziehungen
                    </li>
                </ul>
                <u>Kontinente und Regionen</u>
                <ul>
                    <li>
                        Europa, Europäische Union, Euro-Region, OECD,
                        Nordamerika, Ostasien, südlich der Sahelzone, Failing
                        States und andere mehr…
                    </li>
                </ul>

                <u>Entwicklungsstrategien und Entwicklungstheorien</u>
                <hr />
                <h3 id="Anforderungsbereiche">Anforderungsbereiche</h3>
                <p>
                    Durch den Unterricht mit offenen Daten können drei
                    Anforderungsbereiche bedient werden:
                </p>
                <ul>
                    <li>
                        Anforderungsbereich I umfasst das Wiedergeben von
                        Sachverhalten und Kenntnissen sowie das Anwenden und
                        Beschreiben geübter Arbeitstechniken und Verfahren.
                    </li>
                    <li>
                        Anforderungsbereich II umfasst das selbstständige
                        Verarbeiten und Darstellen bekannter Sachverhalte in
                        einem durch Übung bekannten Zusammenhang und das
                        selbstständige Übertragen des Gelernten auf
                        vergleichbare, neue Sachverhalte.
                    </li>

                    <li>
                        Anforderungsbereich III umfasst das Verarbeiten
                        komplexer Sachverhalte mit selbstständiger Auswahl
                        geeigneter Arbeitstechniken mit dem Ziel, zu
                        selbstständigen Lösungen, Gestaltungen oder Deutungen,
                        Folgerungen, Verallgemeinerungen, Begründungen und
                        Wertungen zu gelangen und das eigene Vorgehen zu
                        reflektieren.
                    </li>
                </ul>
                <hr />
                <h3 id="Unterricht">
                    Unterricht an allgemeinbildenden und beruflichen Schulen
                </h3>
                <p>
                    Unterricht mit digitalen und offenen Daten kann sowohl in
                    allgemeinbildenden Schulen als auch in beruflichen Schulen
                    realisiert werden. Berufliche Schulen mit den
                    Schwerpunktfächern Wirtschaft,
                    Gemeinschaftskunde/Sozialkunde/Politik und
                    Wirtschaftsgeografie können offene Daten schwerpunktmäßig
                    nutzen.
                </p>
            </div>
            <div className="df-data-filter-selector-button-wrapper">
                <AboutCancelButton />
            </div>
        </div>
    );
}
