import stopAutoPlay from "./stopAutoPlay";
import setValue from "./setValue";

const context = "timeSlider";

export default function disposeTimeSliderContext() {
    return (dispatch, getState) => {
        const stateContext = getState().context[context];

        dispatch(stopAutoPlay());
        dispatch(setValue({ value: stateContext.value }));

        document.removeEventListener("keyup", stateContext.onKeyUp);
    };
}
