import PropTypes from "prop-types";
import React from "react";
import {
    List,
    ListItem,
    ListItemAction,
    ListItemContent,
} from "react-mdl/lib/List";
import { Checkbox } from "react-mdl";

DataSourceList.propTypes = {
    onDataSourceSelect: PropTypes.func,
    dataSources: PropTypes.array,
    selectedDataSourceIds: PropTypes.array,
    chartType: PropTypes.string,
};

function DataSourceList({
    dataSources = [],
    selectedDataSourceIds = [],
    onDataSourceSelect = Function.prototype,
    chartType,
}) {
    return (
        <List className="df-multi-line-list">
            {dataSources.map((dataSource) => {
                function selectMultipleIds() {
                    const newSelectedDataSourceIds = Array.from(
                        selectedDataSourceIds
                    );

                    if (selectedDataSourceIds.includes(dataSource.id)) {
                        const index =
                            newSelectedDataSourceIds.indexOf(dataSource);

                        newSelectedDataSourceIds.splice(index, 1);
                    } else {
                        newSelectedDataSourceIds.push(dataSource.id);
                    }

                    onDataSourceSelect({ ids: newSelectedDataSourceIds });
                }

                return chartType === "radar" ? (
                    <ListItem
                        key={dataSource.id}
                        threeLine
                        onClick={selectMultipleIds}
                    >
                        <ListItemContent
                            subtitle={`${dataSource.description} Quelle: ${dataSource.source}`}
                        >
                            {dataSource.title}
                        </ListItemContent>
                        <ListItemAction>
                            <Checkbox
                                id="checkbox1"
                                className="mdl-checkbox__input"
                                checked={selectedDataSourceIds.includes(
                                    dataSource.id
                                )}
                                onChange={selectMultipleIds}
                            />
                        </ListItemAction>
                    </ListItem>
                ) : (
                    <ListItem
                        key={dataSource.id}
                        threeLine
                        onClick={() =>
                            onDataSourceSelect({ ids: [dataSource.id] })
                        }
                    >
                        <ListItemContent
                            subtitle={`${dataSource.description} Quelle: ${dataSource.source}`}
                        >
                            {dataSource.title}
                        </ListItemContent>
                        <ListItemAction>
                            <i className="material-icons">insert_chart</i>
                        </ListItemAction>
                    </ListItem>
                );
            })}
        </List>
    );
}

export default DataSourceList;
