const INDICATOR_PATH = "/data/indicators";
const headers = new Headers({
    Authorization: "Basic cGVlcjpEQVRBRk9WRUFQRUVSSUdPTg==",
});

export default function loadData(id) {
    return fetch(`${INDICATOR_PATH}/${id}.json`, { headers }).then((data) =>
        data.json()
    );
}
