import PropTypes from "prop-types";
import React from "react";

AppLayout.propTypes = {
    children: PropTypes.element.isRequired,
};

function AppLayout({ children }) {
    return <div className="df-page">{children}</div>;
}

export default AppLayout;
