import regions from "../../data/regions";
import regionGroupsData from "../../data/regionGroups";

export const regionMap = Object.create(null);

regions.forEach((region) => {
    regionMap[region.regionId] = region;
});

export const regionIds = Object.keys(regionMap);

export const regionGroups = regionGroupsData.map((regionGroup) => ({
    ...regionGroup,
    regions: regionGroup.regions.map((regionId) => regionMap[regionId]),
}));
