import PropTypes from "prop-types";
import React from "react";

HorizontalBarPage.propTypes = {
    HorizontalBarChart: PropTypes.func.isRequired,
};

function HorizontalBarPage({ HorizontalBarChart }) {
    return (
        <div>
            <HorizontalBarChart />
        </div>
    );
}

export default HorizontalBarPage;
