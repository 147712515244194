/* eslint-disable no-invalid-this */

import PropTypes from "prop-types";
import React from "react";
import equal from "fast-deep-equal";
import { Bubble } from "react-chartjs-2";
import {
    getDefaultChartOptions,
    labels,
} from "../../helpers/chart/getDefaultChartOptions";
import ChartBackground from "../genericChart/ChartBackground";

function tooltipLabelCallback(value) {
    return [
        `${this._data.xTitle}: ${new Intl.NumberFormat().format(value.xLabel)}`,
        `${this._data.yTitle}: ${new Intl.NumberFormat().format(value.yLabel)}`,
    ];
}

function chartOptions(xScale, yScale, xScaleTicks, yScaleTicks) {
    const chartOptions = getDefaultChartOptions(
        yScaleTicks,
        xScaleTicks,
        yScale,
        xScale
    );

    chartOptions.tooltips.callbacks.label = tooltipLabelCallback;
    chartOptions.scales.xAxes[0].ticks.autoSkipPadding = 150;
    chartOptions.scales.xAxes[0].ticks.maxRotation = 0;
    chartOptions.scales.xAxes[0].ticks.callback = labels(xScaleTicks.min);

    // The chart should occupy as much space as possible
    chartOptions.maintainAspectRatio = false;

    return chartOptions;
}

class BubbleChart extends React.Component {
    render() {
        const { data, xScale, yScale, xScaleTicks, yScaleTicks } = this.props;
        const lastScaleAxisConfig = this.scaleAxisConfig;
        const scaleAxisConfig = [xScale, yScale, xScaleTicks, yScaleTicks];
        const redrawAxis =
            equal(lastScaleAxisConfig, scaleAxisConfig) === false;

        return (
            <div className="df-chart">
                <ChartBackground
                    currentYear={data === undefined ? null : data.year}
                />
                {data ? (
                    <Bubble
                        data={data}
                        redraw={redrawAxis}
                        options={chartOptions(
                            xScale,
                            yScale,
                            xScaleTicks,
                            yScaleTicks
                        )}
                    />
                ) : null}
            </div>
        );
    }
}

BubbleChart.propTypes = {
    data: PropTypes.object,
    xScale: PropTypes.string,
    yScale: PropTypes.string,
    xScaleTicks: PropTypes.object,
    yScaleTicks: PropTypes.object,
};

export default BubbleChart;
