import PropTypes from "prop-types";
import React from "react";

RadarPage.propTypes = {
    RadarChart: PropTypes.func.isRequired,
};

function RadarPage({ RadarChart }) {
    return (
        <div>
            <RadarChart />
        </div>
    );
}

export default RadarPage;
