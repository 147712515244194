import { mergeState } from "../../context.actions";
import {
    minYear as minYearConstant,
    maxYear as maxYearConstant,
} from "../../../constants";

const context = "chart";

export default function selectXScaleTicks({
    axis = "x",
    minYear = minYearConstant,
    maxYear = maxYearConstant,
    minNotZero,
    crossesZero,
}) {
    return (dispatch) => {
        dispatch(
            mergeState({
                context,
                state: {
                    [`${axis}ScaleTicks`]: {
                        minYear,
                        maxYear,
                        minNotZero,
                        crossesZero,
                    },
                },
            })
        );
    };
}
