import PropTypes from "prop-types";
import React from "react";

WorldMapPage.propTypes = {
    WorldMapChart: PropTypes.func.isRequired,
};

function WorldMapPage({ WorldMapChart }) {
    return (
        <div>
            <WorldMapChart />
        </div>
    );
}

export default WorldMapPage;
