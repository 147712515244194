import { mergeState } from "../../context.actions";

const context = "dataFilter";

export default function setSearchTerm(searchTerm) {
    return (dispatch) => {
        dispatch(
            mergeState({
                context,
                state: {
                    searchTerm,
                },
            })
        );
    };
}
