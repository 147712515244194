import PropTypes from "prop-types";
import React from "react";
import logo from "../../assets/images/datafovea-logo.svg";

Logo.propTypes = {
    className: PropTypes.string,
};

export default function Logo({ className = "" }) {
    return (
        <a href="/" className={`df-logo ${className}`}>
            <img src={logo} alt="Data Fovea" />
        </a>
    );
}
