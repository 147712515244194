import { initState } from "../../context.actions";
import {
    defaultSelectedCountryIds,
    defaultSelectedRegionIds,
} from "../../../settings/settings";

const context = "dataFilter";
const initialState = {
    modalOpen: false,
    searchTerm: "",
    selectedCountryIds: defaultSelectedCountryIds,
    selectedRegionIds: defaultSelectedRegionIds,
    confirmedSelection: [],
};

export default function initDataFilterContext() {
    return (dispatch, getState) => {
        const state = getState();
        const previousContext = state.context[context];

        if (previousContext === undefined) {
            dispatch(
                initState({
                    context,
                    state: initialState,
                })
            );
        }
    };
}
