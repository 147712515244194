export default function filterCategories({
    categories,
    catId,
    includeAlternatives = false,
}) {
    catId = ["", ...catId.split(".")];

    return categories.reduce((filtered, cat) => {
        const currentCatId = ["", ...cat.id.split(".")];
        // casts to 1 or 0 depending on includeAlternatives
        const length = currentCatId.length - Boolean(includeAlternatives);

        for (let i = 0; i < length; i++) {
            if (catId[i] !== currentCatId[i]) {
                return filtered;
            }
        }

        return [...filtered, cat];
    }, []);
}
