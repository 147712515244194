import PropTypes from "prop-types";
import React from "react";
import Grid from "react-mdl/lib/Grid/Grid";
import Cell from "react-mdl/lib/Grid/Cell";
import Logo from "../common/Logo";

function getChart(
    chartType,
    {
        LinePage,
        RadarPage,
        BubblePage,
        VerticalBarPage,
        HorizontalBarPage,
        WorldMapPage,
    }
) {
    let page;

    switch (chartType) {
        case "line":
            page = <LinePage />;
            break;
        case "radar":
            page = <RadarPage />;
            break;
        case "bubble":
            page = <BubblePage />;
            break;
        case "verticalBar":
            page = <VerticalBarPage />;
            break;
        case "horizontalBar":
            page = <HorizontalBarPage />;
            break;
        case "worldMap":
            page = <WorldMapPage />;
            break;
        default:
            throw new TypeError(`Unexpected chartType value: ${chartType}`);
    }

    return page;
}

function hasXAxis(chartType) {
    switch (chartType) {
        case "bubble":
            return true;
        default:
            return false;
    }
}

GenericChartPage.propTypes = {
    ChartHeader: PropTypes.func.isRequired,
    ChartFooter: PropTypes.func.isRequired,
    DataSourceComponent: PropTypes.func.isRequired,
    DataFilterButton: PropTypes.func.isRequired,
    DataFilterComponent: PropTypes.func.isRequired,
    TimeSlider: PropTypes.func.isRequired,
    AutoPlayButton: PropTypes.func.isRequired,
    YDataSourceButton: PropTypes.func.isRequired,
    YDataSourceButtonBlank: PropTypes.func.isRequired,
    YDataSourceDeleteButton: PropTypes.func.isRequired,
    YToggleScaleButton: PropTypes.func.isRequired,
    YSourceInfoTooltip: PropTypes.func.isRequired,
    Impressum: PropTypes.func.isRequired,
    About: PropTypes.func.isRequired,
    ChartType: PropTypes.string.isRequired,
    VerticalBarPage: PropTypes.func.isRequired,
    HorizontalBarPage: PropTypes.func.isRequired,
    BubblePage: PropTypes.func.isRequired,
    WorldMapPage: PropTypes.func.isRequired,
    LinePage: PropTypes.func.isRequired,
    RadarPage: PropTypes.func.isRequired,
    XDataSourceButton: PropTypes.func.isRequired,
    XDataSourceButtonBlank: PropTypes.func.isRequired,
    XToggleScaleButton: PropTypes.func.isRequired,
    XSourceInfoTooltip: PropTypes.func.isRequired,
};

function GenericChartPage({
    ChartHeader,
    ChartFooter,
    YDataSourceButton,
    YDataSourceButtonBlank,
    YDataSourceDeleteButton,
    DataFilterButton,
    DataSourceComponent,
    DataFilterComponent,
    TimeSlider,
    AutoPlayButton,
    YToggleScaleButton,
    YSourceInfoTooltip,
    Impressum,
    About,
    ChartType,
    VerticalBarPage,
    HorizontalBarPage,
    BubblePage,
    WorldMapPage,
    LinePage,
    RadarPage,
    XDataSourceButton,
    XDataSourceButtonBlank,
    XToggleScaleButton,
    XSourceInfoTooltip,
}) {
    const chartType = getChart(ChartType, {
        BubblePage,
        LinePage,
        RadarPage,
        VerticalBarPage,
        HorizontalBarPage,
        WorldMapPage,
    });
    const renderXAxis = hasXAxis(ChartType);

    return (
        <div className="df-vertical-bar-page">
            <About />
            <DataSourceComponent />
            <DataFilterComponent />
            <Grid className="df-header">
                <Cell col={1}>
                    <Logo className="df-logo" />
                </Cell>
                <Cell col={11} className="df-header__buttons">
                    <h1>1.</h1>
                    <Cell>
                        <DataFilterButton />
                    </Cell>
                    <ChartHeader />
                    <h1>3.</h1>
                    <Cell>
                        <YDataSourceButtonBlank />
                        {renderXAxis && <XDataSourceButtonBlank />}
                    </Cell>
                </Cell>
            </Grid>
            <Grid>
                <Cell col={1}>
                    <div className="df-axis-controls df-axis-controls--vertical">
                        <YDataSourceDeleteButton />
                        <YDataSourceButton />
                        <YToggleScaleButton />
                        <YSourceInfoTooltip />
                    </div>
                </Cell>
                <Cell
                    col={11}
                    phone={3}
                    tablet={7}
                    className="df-chart-wrapper"
                >
                    {chartType}
                </Cell>
                {/* Only bubble chart has a x axis at the moment */}
                {renderXAxis && (
                    <Cell col={11} offset={1}>
                        <div className="df-axis-controls">
                            <XDataSourceButton />
                            <XToggleScaleButton />
                            <XSourceInfoTooltip />
                        </div>
                    </Cell>
                )}
            </Grid>
            <Grid className="df-time-wrapper">
                <Cell col={12}>
                    <div className="df-time-controls">
                        <div className="df-time-controls__auto-play-button">
                            <AutoPlayButton />
                        </div>
                        <div className="df-time-controls__time-slider">
                            <TimeSlider />
                        </div>
                    </div>
                </Cell>
            </Grid>
            <ChartFooter />
            <Impressum />
        </div>
    );
}

export default GenericChartPage;
