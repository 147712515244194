import PropTypes from "prop-types";
import React from "react";
import { List, ListItem, ListItemContent } from "react-mdl/lib/List";

DataFilterCountrySelection.propTypes = {
    selectedCountries: PropTypes.array,
    onCountryClick: PropTypes.func,
};

export default function DataFilterCountrySelection({
    selectedCountries = [],
    onCountryClick = Function.prototype,
}) {
    return (
        <List className="df-data-filter-country-selection">
            {selectedCountries.map((country) => (
                <ListItem
                    key={country.countryId}
                    onClick={() => onCountryClick(country.countryId)}
                >
                    <ListItemContent>{country.nameDE}</ListItemContent>
                </ListItem>
            ))}
        </List>
    );
}
