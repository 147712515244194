import { connect } from "react-redux";
import RadarPage from "../views/radarChart/RadarPage";
import RadarChart from "../views/radarChart/RadarChart";

const ConnectedRadarChart = connect((state) => ({
    data: state.context.chart.preparedData,
    xScaleRange: state.context.chart.xScaleRange,
    yScaleRange: state.context.chart.yScaleRange,
    xScale: state.context.chart.xScale,
    yScale: state.context.chart.yScale,
    xScaleTicks: state.context.chart.xScaleTicks,
    yScaleTicks: state.context.chart.yScaleTicks,
}))(RadarChart);

export default connect((state) => ({
    RadarChart: ConnectedRadarChart,
}))(RadarPage);
