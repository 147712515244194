import { MapControl, withLeaflet } from "react-leaflet";
import { Control, DomUtil } from "leaflet";

function initLabels(props) {
    const labelsSet = props.data;
    const grades = props.colors;

    // returns objects for labels with color, min value & max value
    return labelsSet.map((item, index) => ({
        min: item[0],
        max: item[1],
        color: grades[index],
    }));
}

const legendClassPrefix = "df-chart-worldmap-legend";
const numberFormatDe = new Intl.NumberFormat("de-DE");

function legendRowFactory(label) {
    return `
        <div class="${legendClassPrefix}__color" style="background-color: ${
        label.color
    }"></div>
        <div class="${legendClassPrefix}__min">${numberFormatDe.format(
        label.min
    )}</div>
        <div class="${legendClassPrefix}__delimiter"> - </div>
        <div class="${legendClassPrefix}__max">${numberFormatDe.format(
        label.max
    )}</div>
    `;
}

class WorldMapLegend extends MapControl {
    createLeafletElement(props) {
        const WorldMapLegendClass = Control.extend({
            onAdd: (map) => {
                this.panelDiv = DomUtil.create(
                    "div",
                    "df-chart-worldmap-legend"
                );

                const labels = initLabels(this.props);
                const legendHtml = `
                    <div class="${legendClassPrefix}__year">${
                    this.props.year
                }</div>
                    <div class="${legendClassPrefix}__grid">
                        ${labels.map(legendRowFactory).join("")}
                    </div>
                `;

                this.panelDiv.innerHTML = legendHtml;

                return this.panelDiv;
            },
        });

        return new WorldMapLegendClass({ position: "bottomright" });
    }

    componentDidMount() {
        const { map } = this.props.leaflet;

        this.leafletElement.addTo(map);
    }

    componentDidUpdate() {
        const { map } = this.props.leaflet;

        this.leafletElement.addTo(map);
    }
}

export default withLeaflet(WorldMapLegend);
