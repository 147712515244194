import { mergeState } from "../../context.actions";
import { minYear } from "../../../constants";

const context = "timeSlider";
const currentYear = new Date().getFullYear().toString();

export default function setRange({
    min = minYear.toString(),
    max = currentYear,
} = {}) {
    return (dispatch) => {
        dispatch(
            mergeState({
                context,
                state: {
                    min,
                    max,
                },
            })
        );
    };
}
