import PropTypes from "prop-types";
import React from "react";
import Tooltip from "react-mdl/lib/Tooltip";
import Icon from "react-mdl/lib/Icon";

SourceInfoTooltip.propTypes = {
    position: PropTypes.string,
    source: PropTypes.string,
};

function SourceInfoTooltip({ position = "right", source = "" }) {
    return (
        <Tooltip label={source} position={position} large={true}>
            <Icon className="df-axis-source-info-tooltip" name="info_outline" />
        </Tooltip>
    );
}

export default SourceInfoTooltip;
