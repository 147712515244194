/* eslint-disable no-invalid-this */

import PropTypes from "prop-types";
import React from "react";
import equal from "fast-deep-equal";
import { Radar } from "react-chartjs-2";
import { getDefaultChartOptions } from "../../helpers/chart/getDefaultChartOptions";
import ChartBackground from "../genericChart/ChartBackground";

function tooltipTitleCallback(value) {
    const [{ datasetIndex }] = value;

    if (this._data.datasets[datasetIndex].meta[0]) {
        const countryName = this._data.datasets[datasetIndex].meta[0].nameDE;
        const regionName =
            this._data.datasets[datasetIndex].meta[0].regionNameDE;

        return `${countryName}, ${regionName}`;
    }
}

function tooltipLabelCallback(value) {
    const index = value.index;

    return [
        `${this._data.labels[index]}: ${new Intl.NumberFormat().format(
            value.yLabel
        )}`,
    ];
}

function chartOptions(yScale, yScaleTicks, xScaleTicks) {
    const chartOptions = getDefaultChartOptions(
        yScaleTicks,
        xScaleTicks,
        yScale
    );

    chartOptions.scales = {};

    chartOptions.tooltips.callbacks.title = tooltipTitleCallback;
    chartOptions.tooltips.callbacks.label = tooltipLabelCallback;

    chartOptions.hover = {
        onHover: (event, chartElement) => {
            event.target.style.cursor = chartElement[0] ? "pointer" : "default";
        },
    };

    // The chart should occupy as much space as possible
    chartOptions.maintainAspectRatio = false;

    return chartOptions;
}

class RadarChart extends React.Component {
    render() {
        const { data, yScale, yScaleTicks, xScaleTicks } = this.props;
        const lastScaleAxisConfig = this.scaleAxisConfig;
        const scaleAxisConfig = [yScale, yScaleTicks, xScaleTicks];
        const redrawAxis =
            equal(lastScaleAxisConfig, scaleAxisConfig) === false;

        return (
            <div className="df-chart">
                <ChartBackground
                    currentYear={data === undefined ? null : data.year}
                />
                {data ? (
                    <Radar
                        data={data}
                        redraw={redrawAxis}
                        options={chartOptions(yScale, yScaleTicks, xScaleTicks)}
                    />
                ) : null}
            </div>
        );
    }
}

RadarChart.propTypes = {
    data: PropTypes.object,
    yScale: PropTypes.string,
    yScaleTicks: PropTypes.object,
    xScaleTicks: PropTypes.object,
};

export default RadarChart;
