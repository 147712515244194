import { mergeState } from "../../context.actions";

const context = "dataFilter";

export default function toggleRegionSelection(regionId) {
    return (dispatch, getState) => {
        const { selectedRegionIds } = getState().context[context];

        dispatch(
            mergeState({
                context,
                state: {
                    selectedRegionIds: selectedRegionIds.includes(regionId)
                        ? selectedRegionIds.filter((id) => id !== regionId)
                        : selectedRegionIds.concat(regionId),
                },
            })
        );
    };
}
