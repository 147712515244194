import PropTypes from "prop-types";
import React from "react";

BubblePage.propTypes = {
    BubbleChart: PropTypes.func.isRequired,
};

function BubblePage({ BubbleChart }) {
    return (
        <div>
            <BubbleChart />
        </div>
    );
}

export default BubblePage;
