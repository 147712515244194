import { connect } from "react-redux";
import Modal from "../views/common/Modal";
import toggleCountrySelection from "../actions/context/dataFilter/toggleCountrySelection";
import toggleRegionSelection from "../actions/context/dataFilter/toggleRegionSelection";
import toggleCountrySelectionByRegionId from "../actions/context/dataFilter/toggleCountrySelectionByRegionId";
import clearDataFilterSelection from "../actions/context/dataFilter/clearDataFilterSelection";
import confirmDataFilterSelection from "../actions/context/dataFilter/confirmDataFilterSelection";
import closeDataFilterModal from "../actions/context/dataFilter/closeDataFilterModal";
import setSearchTerm from "../actions/context/dataFilter/setSearchTerm";
import DataFilterSelector from "../views/dataFilter/DataFilterSelector";
import DataFilterCountrySearch from "../views/dataFilter/DataFilterCountrySearch";
import DataFilterCountryList from "../views/dataFilter/DataFilterCountryList";
import DataFilterRegionList from "../views/dataFilter/DataFilterRegionList";
import DataFilterButton from "../views/dataFilter/DataFilterButton";
import DataFilterCountrySelection from "../views/dataFilter/DataFilterCountrySelection";
import DataFilterRegionSelection from "../views/dataFilter/DataFilterRegionSelection";
import DataFilterRegionSelector from "../views/dataFilter/DataFilterRegionSelector";
import { sortedRegions } from "../helpers/dataFilter/regions";
import { regionGroups } from "../helpers/data/regions";
import countries from "../data/countries";
import regions from "../data/regions";

const ConnectedDataFilterCountrySearch = connect(
    (state) => ({
        searchTerm: state.context.dataFilter.searchTerm,
    }),
    {
        onSearchInput: setSearchTerm,
    }
)(DataFilterCountrySearch);
const ConnectedDataFilterRegionSelector = connect(
    (state) => ({
        regions: sortedRegions,
    }),
    {
        onSelectRegion: toggleCountrySelectionByRegionId,
    }
)(DataFilterRegionSelector);
const ConnectedDataFilterCountryList = connect(
    (state) => {
        const { selectedCountryIds, searchTerm } = state.context.dataFilter;

        return {
            countries: countries.filter(
                (country) =>
                    country.regionId !== "NA" &&
                    country.nameDE
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()) &&
                    selectedCountryIds.includes(country.countryId) === false
            ),
        };
    },
    {
        onSelectCountry: toggleCountrySelection,
    }
)(DataFilterCountryList);
const ConnectedDataFilterRegionList = connect(
    (state) => {
        const { selectedRegionIds, searchTerm } = state.context.dataFilter;

        return {
            regionGroups: regionGroups
                .map((regionGroup) => ({
                    ...regionGroup,
                    regions: regionGroup.regions.filter(
                        (region) =>
                            region.nameDE
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase()) &&
                            selectedRegionIds.includes(region.regionId) ===
                                false
                    ),
                }))
                .filter((regionGroup) => regionGroup.regions.length > 0),
        };
    },
    {
        onSelectRegion: toggleRegionSelection,
    }
)(DataFilterRegionList);
const ConnectedDataFilterCountrySelection = connect(
    (state) => {
        const { selectedCountryIds } = state.context.dataFilter;

        return {
            selectedCountries: countries.filter((country) =>
                selectedCountryIds.includes(country.countryId)
            ),
        };
    },
    {
        onCountryClick: toggleCountrySelection,
    }
)(DataFilterCountrySelection);
const ConnectedDataFilterRegionSelection = connect(
    (state) => {
        const { selectedRegionIds } = state.context.dataFilter;

        return {
            selectedRegions: regions.filter((region) =>
                selectedRegionIds.includes(region.regionId)
            ),
        };
    },
    {
        onRegionClick: toggleRegionSelection,
    }
)(DataFilterRegionSelection);
const ConnectedDataFilterFilterButton = connect(
    (state) => ({
        children: "Filtern",
        raised: true,
        ripple: true,
    }),
    {
        onClick: confirmDataFilterSelection,
    }
)(DataFilterButton);
const ConnectedDataFilterCancelButton = connect(
    (state) => ({
        children: "Abbrechen",
        raised: true,
        ripple: true,
    }),
    {
        onClick: closeDataFilterModal,
    }
)(DataFilterButton);
const ConnectedDataFilterDeleteButton = connect(
    (state) => ({
        children: "Löschen",
        raised: true,
        ripple: true,
    }),
    {
        onClick: clearDataFilterSelection,
    }
)(DataFilterButton);
const ConnectedDataFilterSelector = connect((state) => ({
    DataFilterCountrySearch: ConnectedDataFilterCountrySearch,
    DataFilterCountryList: ConnectedDataFilterCountryList,
    DataFilterRegionList: ConnectedDataFilterRegionList,
    DataFilterCountrySelection: ConnectedDataFilterCountrySelection,
    DataFilterRegionSelection: ConnectedDataFilterRegionSelection,
    DataFilterRegionSelector: ConnectedDataFilterRegionSelector,
    DataFilterCancelButton: ConnectedDataFilterCancelButton,
    DataFilterFilterButton: ConnectedDataFilterFilterButton,
    DataFilterDeleteButton: ConnectedDataFilterDeleteButton,
}))(DataFilterSelector);

export default connect(
    (state) => {
        const modalOpen = state.context.dataFilter.modalOpen;

        return {
            className: "df-data-filter-modal",
            open: modalOpen,
            title: "Wähle die Regionen und Länder",
            View: modalOpen ? ConnectedDataFilterSelector : null,
        };
    },
    {
        onCancel: closeDataFilterModal,
    }
)(Modal);
