import { mergeState } from "../../context.actions";

const context = "timeSlider";

export default function setValue({ value }) {
    return (dispatch) => {
        dispatch(
            mergeState({
                context,
                state: {
                    value,
                },
            })
        );
    };
}
