import React from "react";
import PropTypes from "prop-types";
import { telephoneNumber, emailHref, emailText } from "../../constants";

Impressum.propTypes = {
    ImpressumCancelButton: PropTypes.func,
};

export default function Impressum({ ImpressumCancelButton }) {
    return (
        <div href="/" className="df-impressum-modal">
            <div className="df-impressum-modal__text">
                <p>
                    DATAFOVEA Gesellschaft mit beschränkter Haftung (GmbH){" "}
                    <br />
                    Vertretungsberechtigter Geschäftsführer: Dr. Peer Egtved{" "}
                    <br />
                    Leberstraße 8 <br />
                    24937 Flensburg
                </p>
                <p>
                    Registergericht: Amtsgericht Flensburg <br />
                    Registernummer: HRB 297/2014 <br />
                    Telefon {telephoneNumber} <br />
                    E-Mail <a href={emailHref}>{emailText}</a>
                </p>
                <p>Europäische Online- Streitbeilegungs-Plattform</p>
                <p>
                    Gemäß der EU-Verordnung Nr. 524/2013 über die
                    Online-Streitbeilegung richtet die Europäische Kommission
                    eine Online-Plattform zur außergerichtlichen Streitbeilegung
                    ein. Die Online-Plattform ist unter
                    http://ec.europa.eu/consumers/odr erreichbar. <br />
                    Unsere E-Mailadresse lautet: {emailText} <br />
                    Wir nehmen an einem Streitbeilegungsverfahren vor einer
                    Verbraucherschlichtungsstelle nicht teil.
                </p>

                <p>Haftung für Inhalte</p>
                <p>
                    Die verwendeten Daten stammen aus unterschiedlichen
                    Datenbanken, zumeist jedoch aus den Datenbanken der Weltbank
                    und sind als Offene Daten („Open Data“) gekennzeichnet. Die
                    Daten werden direkt vom Datenanbieter bezogen und auf dieser
                    Website verwendet. Wir haften weder für die Richtigkeit der
                    angebotenen Daten noch für die korrekte Verlinkung der Daten
                    oder Namens- und Datenfehler. Die Nutzung der Seite
                    DATAFOVEA geschieht auf eigenen Gefahr, wir haften nicht für
                    Folgeschäden. Als Diensteanbieter sind wir gemäß § 7 Abs.1
                    TMG für eigene Inhalte auf diesen Seiten nach den
                    allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG
                    sind wir als Diensteanbieter jedoch nicht verpflichtet,
                    übermittelte oder gespeicherte fremde Informationen zu
                    überwachen oder nach Umständen zu forschen, die auf eine
                    rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
                    Entfernung oder Sperrung der Nutzung von Informationen nach
                    den allgemeinen Gesetzen bleiben hiervon unberührt. Eine
                    diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
                    Kenntnis einer konkreten Rechtsverletzung möglich. Bei
                    Bekanntwerden von entsprechenden Rechtsverletzungen werden
                    wir diese Inhalte umgehend entfernen.
                </p>
                <p>Haftung für Links</p>
                <p>
                    Unser Angebot enthält Links zu externen Webseiten Dritter,
                    auf deren Inhalte wir keinen Einfluss haben. Deshalb können
                    wir für diese fremden Inhalte auch keine Gewähr übernehmen.
                    Für die Inhalte der verlinkten Seiten ist stets der
                    jeweilige Anbieter oder Betreiber der Seiten verantwortlich.
                    Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung
                    auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
                    waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
                    permanente inhaltliche Kontrolle der verlinkten Seiten ist
                    jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
                    nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen
                    werden wir derartige Links umgehend entfernen.
                </p>
                <p>Urheberrecht</p>
                <p>
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke
                    auf diesen Seiten unterliegen dem deutschen Urheberrecht.
                    Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art
                    der Verwertung außerhalb der Grenzen des Urheberrechtes
                    bedürfen der schriftlichen Zustimmung des jeweiligen Autors
                    bzw. Erstellers. Downloads und Kopien dieser Seite sind nur
                    für den privaten, nicht kommerziellen Gebrauch gestattet.
                    Soweit die Inhalte auf dieser Seite nicht vom Betreiber
                    erstellt wurden, werden die Urheberrechte Dritter beachtet.
                    Insbesondere werden Inhalte Dritter als solche
                    gekennzeichnet. Sollten Sie trotzdem auf eine
                    Urheberrechtsverletzung aufmerksam werden, bitten wir um
                    einen entsprechenden Hinweis. Bei Bekanntwerden von
                    Rechtsverletzungen werden wir derartige Inhalte umgehend
                    entfernen.
                </p>
            </div>
            <div className="df-data-filter-selector-button-wrapper">
                <ImpressumCancelButton />
            </div>
        </div>
    );
}
