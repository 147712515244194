import { mergeState } from "../../context.actions";
import selectCat from "./selectCat";

const context = "dataSource";

export default function openDataSourceModal({ axis }) {
    return (dispatch) => {
        dispatch(
            mergeState({
                context,
                state: {
                    modalOpen: true,
                    axis,
                },
            })
        );
        dispatch(selectCat());
    };
}
