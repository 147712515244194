import loadData from "../../../helpers/dataSource/loadData";
import exec from "../../../helpers/context/exec";
import determineYearRange from "../../../helpers/dataSource/determineYearRange";
import determineScaleTickRange from "../../../helpers/chart/determineScaleTickRange";
import setTimeSliderYearRange from "../../../actions/context/timeSlider/setRange";
import setTimeSliderValue from "../../../actions/context/timeSlider/setValue";
import prepareData from "./prepareDataLineChart";
import selectYear from "../chart/selectYear";
import selectXScaleTicks from "../chart/selectXScaleTicks";
import selectYScaleTicks from "../chart/selectYScaleTicks";
import selectScale from "../chart/selectScale";

const context = "chart";

export default function selectDataSourceLineChart({ id = "" }) {
    return (dispatch, getState) => {
        const { dataSources } = getState();
        const selectedDataSource = dataSources.find((d) => d.id === id) || null;
        const initialState = {
            yDataSource: selectedDataSource,
        };

        exec({
            context,
            initialState,
            tasks: {
                yData() {
                    return selectedDataSource
                        ? loadData(selectedDataSource.indicatorId || id)
                        : Promise.resolve(null);
                },
            },
            dispatch(action) {
                const selectedDataSource =
                    getState().context[context].yDataSource;

                // allow initial state to pass, otherwise the state is not changeable anymore

                if (
                    action.state === initialState ||
                    (selectedDataSource && selectedDataSource.id === id)
                ) {
                    dispatch(action);
                }
            },
        }).then(() => {
            const { yData, yScale } = getState().context[context];
            const { value } = getState().context.timeSlider;

            if (yData) {
                const range = [determineYearRange(Object.keys(yData))];
                const minYear = Math.min(...range.map((range) => range.min));
                const maxYear = Math.max(...range.map((range) => range.max));
                const xScaleTicks = {
                    minYear,
                    maxYear,
                    minNotZero: minYear,
                    crossesZero: false,
                };
                const yScaleTicks = determineScaleTickRange(yData, minYear);
                let currentValue = value;

                if (value < minYear) {
                    currentValue = minYear;
                }
                if (value > maxYear) {
                    currentValue = maxYear;
                }

                dispatch(
                    setTimeSliderYearRange({ min: minYear, max: maxYear })
                );

                dispatch(selectXScaleTicks({ ...xScaleTicks }));
                dispatch(selectYScaleTicks({ ...yScaleTicks }));
                if (yScaleTicks.crossesZero && yScale === "log") {
                    dispatch(selectScale({ axis: "y", scale: "lin" }));
                }

                dispatch(prepareData());

                // update selected data for chart and timeslider
                dispatch(selectYear({ year: currentValue }));
                dispatch(setTimeSliderValue({ value: currentValue }));
            }
        });
    };
}
