import { mergeState } from "../../context.actions";
import prepareDataLineChart from "../lineChart/prepareDataLineChart";
import prepareDataRadarChart from "../radarChart/prepareDataRadarChart";
import prepareDataBubbleChart from "../bubbleChart/prepareDataBubbleChart";
import prepareDataVerticalBarChart from "../verticalBarChart/prepareDataVerticalBarChart";
import prepareDataHorizontalBarChart from "../horizontalBarChart/prepareDataHorizontalBarChart";
import prepareDataWorldMapChart from "../worldMapChart/prepareDataWorldMapChart";
import { defaultYear } from "../../../constants";

const context = "chart";

export default function selectYear({ year = defaultYear }) {
    return (dispatch, getState) => {
        dispatch(
            mergeState({
                context,
                state: {
                    year,
                },
            })
        );

        const { chartType } = getState().context.chart;

        if (chartType === "line") {
            dispatch(prepareDataLineChart());
        } else if (chartType === "bubble") {
            dispatch(prepareDataBubbleChart());
        } else if (chartType === "radar") {
            dispatch(prepareDataRadarChart());
        } else if (chartType === "verticalBar") {
            dispatch(prepareDataVerticalBarChart());
        } else if (chartType === "horizontalBar") {
            dispatch(prepareDataHorizontalBarChart());
        } else if (chartType === "worldMap") {
            dispatch(prepareDataWorldMapChart());
        } else {
            throw new TypeError(`Unexpected chartType value: ${chartType}`);
        }
    };
}
