import { mergeState } from "../../context.actions";
import { minYear } from "../../../constants";
import disposeTimeSliderContext from "../timeSlider/disposeTimeSliderContext";
import setValue from "../timeSlider/setValue";

const context = "chart";

export default function deleteChartContext() {
    return (dispatch, getState) => {
        const state = getState();
        const newChartState = Object.assign({}, state, {
            yDataTicks: "Y-Achse",
            yDataSource: null,
            xDataTicks: "X-Achse",
            xDataSource: null,
            preparedData: undefined,
            year: minYear,
        });

        dispatch(
            mergeState({
                context,
                state: newChartState,
            })
        );

        dispatch(disposeTimeSliderContext());
        dispatch(setValue({ value: minYear }));
    };
}
