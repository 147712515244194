export default function filterErrors({ id, code }) {
    return function errorFilter(error) {
        if (id && id === error.id) {
            return false;
        } else if (code && code === error.code) {
            return false;
        }

        return true;
    };
}
