import PropTypes from "prop-types";
import React from "react";
import Button from "react-mdl/lib/Button";

DataSourceDeleteButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

function DataSourceDeleteButton({ onClick }) {
    return (
        <Button
            className={"df-data-source-button mdl-special-btn"}
            raised
            onClick={onClick}
        >
            <i className="material-icons">delete_forever</i>
        </Button>
    );
}

export default DataSourceDeleteButton;
