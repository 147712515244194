/* eslint-disable complexity */
// eslint-disable-next-line import/extensions
import hexToRgba from "hex-rgba";
import getCountry from "./getCountry";
import { regionMap } from "../data/regions";
import { countryMap } from "../data/countries";

/**
 * Formats data required by Chart.js
 * Adds color information for each country based on region
 *
 * @param {Object} args
 * @returns {Array} formatted country data
 */

function transformIntoChartjsFormat({
    datasets,
    selectedCountryIds = [],
    selectedRegionIds = [],
    chartType,
    xScale,
    yScale,
}) {
    // TODO Pull code for each chart type into dedicated action
    // eslint-disable-next-line complexity
    const selectedCountries = selectedCountryIds.map(
        (countryId) => countryMap[countryId]
    );
    const selectedRegions = selectedRegionIds.map(
        (regionId) => regionMap[regionId]
    );
    const selectedCountriesAndRegions = [
        ...selectedCountries,
        ...selectedRegions.map((region) => ({
            countryId: region.regionId,
            countryISO2Code: region.regionISO2Code,
            regionId: region.regionId,
            nameDE: region.nameDE.split("(")[0],
            nameEN: region.nameEN,
            isRegion: true,
        })),
    ];
    const countryDataMap = datasets.reduce((results, dataItem) => {
        const country = getCountry(
            dataItem.countryISO2Code,
            selectedCountriesAndRegions
        );

        // TODO preprocess indicator data so we don't have to filter data here
        // necessary because some countries and regions in the indicator data are not wanted
        if (
            !country ||
            !selectedCountriesAndRegions.find(
                (c) => c.countryId === country.countryId
            )
        ) {
            return results;
        }

        const region = regionMap[country.regionId];

        if (chartType === "line") {
            // apply styling only once
            results[country.countryId] = results[country.countryId] || {
                countryISO2Code: dataItem.countryISO2Code,
                regionId: region.regionId,
                regionNameDE: region.nameDE,
                borderWidth: 3,
                pointBackgroundColor: country.isRegion
                    ? "#C0C0C0"
                    : hexToRgba(region.color, 1 * 100),
                borderColor: country.isRegion
                    ? "#C0C0C0"
                    : hexToRgba(region.color, 1 * 100),
                pointStyle: "circle",
                pointRadius: 2,
                // pointHoverRadius: 4,
                fill: false,
                data: [],
            };

            // collect all data points for country
            results[country.countryId].data.push({
                x: dataItem.x,
                y: dataItem.y,
                nameDE: country.nameDE,
            });
        } else if (chartType === "verticalBar") {
            // apply styling only once
            results[country.countryId] = results[country.countryId] || {
                countryISO2Code: dataItem.countryISO2Code,
                regionId: region.regionId,
                regionNameDE: region.nameDE.split("(")[0],
                nameDE: country.nameDE,
                backgroundColor: country.isRegion
                    ? "#C0C0C0"
                    : hexToRgba(region.color, 0.8 * 100),
                borderWidth: 1,
                borderColor: "white",
                data: [],
            };

            // VerticalBar chart needs different data format than other charts:
            // https://www.chartjs.org/docs/latest/charts/bar.html#data-structure
            // collect all data points for country
            results[country.countryId].data.push(dataItem.y);
        } else if (chartType === "horizontalBar") {
            // apply styling only once
            results = {
                data: results.data || [],
                backgroundColor: results.backgroundColor || [],
                meta: results.meta || [],
            };

            // HorizontalBar chart needs different data format than other charts:
            // https://www.chartjs.org/docs/latest/charts/bar.html#data-structure
            // collect all data points for country
            results.data.push(dataItem.y);
            results.backgroundColor.push(
                country.isRegion
                    ? "#C0C0C0"
                    : hexToRgba(region.color, 0.8 * 100)
            );
            results.meta.push({
                countryISO2Code: dataItem.countryISO2Code,
                regionId: region.regionId,
                regionNameDE: region.nameDE,
                nameDE: country.nameDE,
            });
        } else if (chartType === "bubble") {
            results[country.countryId] = results[country.countryId] || {
                regionId: region.regionId,
                regionNameDE: region.nameDE,
                borderWidth: 0.5,
                borderColor: "black",
                backgroundColor: country.isRegion
                    ? hexToRgba("#C0C0C0", 0.8 * 100)
                    : hexToRgba(region.color, 0.8 * 100),
                backgroundColorHover: region.color,
                data: [],
            };

            // eslint-disable-next-line no-inner-declarations
            function getOnlyPositiveValues(dataItemValue) {
                if (
                    (xScale === "log" || yScale === "log") &&
                    dataItemValue < 0
                ) {
                    return null;
                }

                return dataItemValue;
            }

            results[country.countryId].data.push({
                countryISO2Code: dataItem.countryISO2Code,
                // x: dataItem.x,
                // y: dataItem.y,
                x: getOnlyPositiveValues(dataItem.x),
                y: getOnlyPositiveValues(dataItem.y),
                r: dataItem.r,
                nameDE: country.nameDE,
            });
        } else if (chartType === "radar") {
            return;
        } else {
            throw new TypeError(`Unexpected chartType value: ${chartType}`);
        }

        return results;
    }, {});

    if (chartType === "horizontalBar") {
        return [countryDataMap];
    }

    if (chartType === "radar") {
        return;
    }

    return (
        Object.keys(countryDataMap)
            // prevent regions from jumping around
            .sort()
            .map((dataEntryId) => countryDataMap[dataEntryId])
    );
}

export function transformIntoHorizontalBarChartFormat({
    datasets,
    selectedCountryIds = [],
    selectedRegionIds = [],
    chartType,
}) {
    datasets.sort((a, b) => b.y - a.y);

    const selectedCountries = selectedCountryIds.map(
        (countryId) => countryMap[countryId]
    );
    const selectedRegions = selectedRegionIds.map(
        (regionId) => regionMap[regionId]
    );
    const selectedCountriesAndRegions = [
        ...selectedCountries,
        ...selectedRegions.map((region) => ({
            countryId: region.regionId,
            countryISO2Code: region.regionISO2Code,
            regionId: region.regionId,
            nameDE: region.nameDE.split("(")[0],
            nameEN: region.nameEN,
            isRegion: true,
        })),
    ];
    const countryDataMap = datasets.reduce((results, dataItem) => {
        const country = getCountry(
            dataItem.countryISO2Code,
            selectedCountriesAndRegions
        );

        if (
            !country ||
            !selectedCountriesAndRegions.find(
                (c) => c.countryId === country.countryId
            )
        ) {
            return results;
        }

        const region = regionMap[country.regionId];

        if (chartType === "horizontalBar" && country) {
            results = {
                data: results.data || [],
                backgroundColor: results.backgroundColor || [],
                meta: results.meta || [],
            };

            results.data.push(dataItem.y);
            results.backgroundColor.push(
                country.isRegion
                    ? hexToRgba("#C0C0C0", 0.8 * 100)
                    : hexToRgba(region.color, 0.8 * 100)
            );
            results.meta.push({
                countryISO2Code: dataItem.countryISO2Code,
                regionId: region.regionId,
                regionNameDE: region.nameDE.split("(")[0],
                nameDE: country.nameDE,
            });
        }

        return results;
    }, {});

    return [countryDataMap];
}

export function transformIntoRadarChartFormat({
    datasets,
    selectedCountryIds = [],
    selectedRegionIds = [],
}) {
    // TODO Pull code for each chart type into dedicated action
    // eslint-disable-next-line complexity
    const selectedCountries = selectedCountryIds.map(
        (countryId) => countryMap[countryId]
    );
    const selectedRegions = selectedRegionIds.map(
        (regionId) => regionMap[regionId]
    );
    const selectedCountriesAndRegions = [
        ...selectedCountries,
        ...selectedRegions.map((region) => ({
            countryId: region.regionId,
            countryISO2Code: region.regionISO2Code,
            regionId: region.regionId,
            nameDE: region.nameDE.split("(")[0],
            nameEN: region.nameEN,
            isRegion: true,
        })),
    ];
    const countries = [];
    const regions = [];
    const dataItems = [];
    const countryDataMap = datasets.reduce((results, dataItem) => {
        selectedCountriesAndRegions.map((s) => {
            if (dataItem.length === 0) {
                dataItems.push({
                    x: undefined,
                    y: undefined,
                    countryISO2Code: s.countryISO2Code,
                });
            } else {
                dataItem.map((d) => {
                    if (
                        d.countryISO2Code === s.countryISO2Code &&
                        !countries.includes(s)
                    ) {
                        countries.push(s);
                        regions.push(regionMap[s.regionId]);
                    }
                    if (d.countryISO2Code === s.countryISO2Code) {
                        dataItems.push(d);
                    }
                });
            }
        });

        // TODO preprocess indicator data so we don't have to filter data here
        // necessary because some countries and regions in the indicator data are not wanted
        if (!countries) {
            return results;
        }

        return countries.map((c, i) => {
            const modifiedData = [];

            dataItems
                .filter((d) => c.countryISO2Code === d.countryISO2Code)
                .map((d) => modifiedData.push(d.y));

            return {
                countryISO2Code: dataItems[i].countryISO2Code,
                regionId: regions[i].regionId,
                regionNameDE: regions[i].nameDE.split("(")[0],
                nameDE: c.nameDE,
                pointBackgroundColor: c.isRegion
                    ? "#C0C0C0"
                    : hexToRgba(regions[i].color, 1 * 100),
                backgroundColor: c.isRegion
                    ? hexToRgba("#C0C0C0", 0.2 * 100)
                    : hexToRgba(regions[i].color, 0.2 * 100),
                borderWidth: 1,
                borderColor: "white",
                data: modifiedData,
                meta: [
                    {
                        countryISO2Code: c.countryISO2Code,
                        regionId: regions[i].regionId,
                        regionNameDE: regions[i].nameDE,
                        nameDE: c.nameDE,
                    },
                ],
            };
        });
    }, {});

    return (
        Object.keys(countryDataMap)
            // prevent regions from jumping around
            .sort()
            .map((dataEntryId) => countryDataMap[dataEntryId])
    );
}

export default transformIntoChartjsFormat;
