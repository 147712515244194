import PropTypes from "prop-types";
import React from "react";

LinePage.propTypes = {
    LineChart: PropTypes.func.isRequired,
};

function LinePage({ LineChart }) {
    return (
        <div>
            <LineChart />
        </div>
    );
}

export default LinePage;
