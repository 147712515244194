import PropTypes from "prop-types";
import React from "react";
import Textfield from "react-mdl/lib/Textfield";

DataFilterCountrySearch.propTypes = {
    searchTerm: PropTypes.string,
    onSearchInput: PropTypes.func,
};

export default function DataFilterCountrySearch({ searchTerm, onSearchInput }) {
    return (
        <Textfield
            className="df-data-filter-country-search"
            onChange={(e) => onSearchInput(e.target.value)}
            label="Suche..."
        />
    );
}
