import PropTypes from "prop-types";
import React from "react";
import Button from "react-mdl/lib/Button";
import SlideList from "../common/SlideList";
import DataSourceCatList from "./DataSourceCatList";
import DataSourceList from "./DataSourceList";
import Breadcrumbs from "../common/Breadcrumbs";

DataSourceSelector.propTypes = {
    categories: PropTypes.array,
    subCategories: PropTypes.array,
    dataSources: PropTypes.array,
    initialSelectedDataSourceIds: PropTypes.array,
    breadcrumbs: PropTypes.array,
    onCatSelect: PropTypes.func,
    onSubCatSelect: PropTypes.func,
    onDataSourceSelect: PropTypes.func,
    chartType: PropTypes.string,
};

function DataSourceSelector({
    categories = [],
    subCategories = [],
    dataSources = [],
    initialSelectedDataSourceIds = [],
    breadcrumbs = [],
    onCatSelect = Function.prototype,
    onSubCatSelect = Function.prototype,
    onDataSourceSelect = Function.prototype,
    chartType = "",
}) {
    const [selectedDataSourceIds, setSelectedDataSourceIds] = React.useState(
        initialSelectedDataSourceIds
    );
    // selecting category
    const children = [
        categories.map((cat, idx) => (
            <DataSourceCatList
                onCatSelect={onCatSelect}
                key={"step1:" + idx}
                items={cat}
            />
        )),
    ];
    const currentStep = breadcrumbs.length;

    if (currentStep === 2 || currentStep === 3) {
        // selecting subCategory
        children.push([
            <DataSourceCatList
                onCatSelect={onSubCatSelect}
                key={"step2:" + currentStep}
                items={subCategories}
            />,
        ]);
    }
    if (currentStep === 3) {
        // selecting dataSources
        children.push(
            categories.map((cat, idx) => (
                <DataSourceList
                    key={"step3:" + idx}
                    dataSources={dataSources}
                    selectedDataSourceIds={selectedDataSourceIds}
                    chartType={chartType}
                    onDataSourceSelect={({ ids }) => {
                        if (chartType === "radar") {
                            setSelectedDataSourceIds(ids);
                        } else {
                            onDataSourceSelect({ ids });
                        }
                    }}
                />
            ))
        );
    }

    return (
        <div>
            <Breadcrumbs onCatSelect={onCatSelect}>{breadcrumbs}</Breadcrumbs>
            <SlideList activeList={currentStep}>{children}</SlideList>
            {chartType === "radar" && currentStep === 3 && (
                <Button
                    raised
                    ripple
                    onClick={() => {
                        onDataSourceSelect({ ids: selectedDataSourceIds });
                    }}
                >
                    Auswählen
                </Button>
            )}
        </div>
    );
}

export default DataSourceSelector;
