import PropTypes from "prop-types";
import React from "react";

DataFilterSelector.propTypes = {
    DataFilterCountrySearch: PropTypes.func,
    DataFilterCountryList: PropTypes.func,
    DataFilterRegionList: PropTypes.func,
    DataFilterCountrySelection: PropTypes.func,
    DataFilterRegionSelection: PropTypes.func,
    DataFilterRegionSelector: PropTypes.func,
    DataFilterCancelButton: PropTypes.func,
    DataFilterFilterButton: PropTypes.func,
    DataFilterDeleteButton: PropTypes.func,
};

export default function DataFilterSelector({
    DataFilterCountrySearch,
    DataFilterCountryList,
    DataFilterRegionList,
    DataFilterCountrySelection,
    DataFilterRegionSelection,
    DataFilterRegionSelector,
    DataFilterCancelButton,
    DataFilterFilterButton,
    DataFilterDeleteButton,
}) {
    return (
        <div>
            <div>
                <DataFilterRegionSelector />
            </div>
            <DataFilterCountrySearch />
            <div className="df-data-filter-selector-country-lists-wrapper">
                <div className="df-data-filter-selector-country-list">
                    Regionen
                    <DataFilterRegionList />
                    Länder
                    <DataFilterCountryList />
                </div>
                <div className="df-data-filter-selector-divider">
                    <div>
                        <div className="material-icons">
                            keyboard_arrow_right
                        </div>
                        <i className="material-icons">keyboard_arrow_left</i>
                    </div>
                    <div>
                        <div className="material-icons">
                            keyboard_arrow_right
                        </div>
                        <i className="material-icons">keyboard_arrow_left</i>
                    </div>
                </div>
                <div className="df-data-filter-selector-country-list">
                    Ausgewählte Regionen
                    <DataFilterRegionSelection />
                    Ausgewählte Länder
                    <DataFilterCountrySelection />
                </div>
            </div>

            <div className="df-data-filter-selector-button-wrapper">
                <DataFilterCancelButton />
                <DataFilterDeleteButton />
                <DataFilterFilterButton />
            </div>
        </div>
    );
}
