import { initState } from "../../context.actions";
import startAutoPlay from "./startAutoPlay";
import stopAutoPlay from "./stopAutoPlay";
import { minYear, maxYear } from "../../../constants";
import isDataSourceSelected from "../../../helpers/dataSource/isDataSourceSelected";
import isModalOpen from "../../../helpers/timeSlider/isModalOpen";

const SPACE_BAR = 32;
const chartContext = "timeSlider";
const initial = {
    min: minYear,
    max: new Date().getFullYear(),
    value: maxYear,
    isRunning: false,
};
let isHandlerActive = false;

function isSpaceBar(e) {
    return e.keyCode === SPACE_BAR;
}

export default function initTimeSliderContext() {
    return (dispatch, getState) => {
        const state = getState();
        const previousContext = state.context[chartContext];
        const initialState = Object.assign({}, initial);

        function onKeyUp(e) {
            const stateContext = getState().context;

            if (
                isSpaceBar(e) &&
                isModalOpen(stateContext) === false &&
                isDataSourceSelected(stateContext) === true
            ) {
                dispatch(
                    stateContext[chartContext].isRunning
                        ? stopAutoPlay()
                        : startAutoPlay()
                );
            }
            e.preventDefault();
        }

        // stop the auto play in case it's still running (e.g. when changing the chart type)
        if (getState().context[chartContext]) {
            dispatch(stopAutoPlay());
        }

        if (previousContext !== undefined) {
            Object.assign(initialState, {
                value: previousContext.value,
            });
        }

        const dispatchInitialState = initState({
            context: chartContext,
            state: {
                ...initialState,
                onKeyUp,
            },
        });

        dispatch(dispatchInitialState);

        if (!isHandlerActive) {
            document.addEventListener("keyup", onKeyUp);
            isHandlerActive = true;
        }
    };
}
