import { connect } from "react-redux";
import Modal from "../views/common/Modal";
import DataSourceSelector from "../views/dataSource/DataSourceSelector";
import selectCat from "../actions/context/dataSource/selectCat";
import selectSubCat from "../actions/context/dataSource/selectSubCat";
import closeDataSourceModal from "../actions/context/dataSource/closeDataSourceModal";
import selectDataSource from "../actions/context/dataSource/selectDataSource";

const ConnectedDataSourceSelector = connect(
    (state) => ({
        categories: state.context.dataSource.categories,
        subCategories: state.context.dataSource.subCategories,
        dataSources: state.context.dataSource.dataSources,
        initialSelectedDataSourceIds:
            state.context.dataSource.selectedDataSourceIds,
        breadcrumbs: state.context.dataSource.breadcrumbs,
        chartType: state.context.chart.chartType,
    }),
    {
        onDataSourceSelect: selectDataSource,
        onCatSelect: selectCat,
        onSubCatSelect: selectSubCat,
    }
)(DataSourceSelector);

function modalOpen(state) {
    return state.context.dataSource.modalOpen;
}

export default connect(
    (state) => ({
        className: "df-data-source-modal",
        open: modalOpen(state),
        title: "Wähle dein Datenabenteuer",
        View: modalOpen(state) ? ConnectedDataSourceSelector : null,
    }),
    {
        onCancel: closeDataSourceModal,
    }
)(Modal);
