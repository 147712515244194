import { mergeState } from "../../context.actions";
import prepareBubbleChartData from "../bubbleChart/prepareDataBubbleChart";
import prepareLineChartData from "../lineChart/prepareDataLineChart";
import prepareVerticalBarChartData from "../verticalBarChart/prepareDataVerticalBarChart";
import prepareHorizontalBarChartData from "../horizontalBarChart/prepareDataHorizontalBarChart";
import prepareWorldMapChartData from "../worldMapChart/prepareDataWorldMapChart";

const context = "chart";

export default function selectScale({ axis = "x", scale = "lin" }) {
    return (dispatch, getState) => {
        const { chartType } = getState().context[context];

        dispatch(
            mergeState({
                context,
                state: {
                    [`${axis}Scale`]: scale,
                },
            })
        );

        if (chartType === "line") {
            dispatch(prepareLineChartData());
        } else if (chartType === "bubble") {
            dispatch(prepareBubbleChartData());
        } else if (chartType === "radar") {
            dispatch(prepareRadarChartData());
        } else if (chartType === "verticalBar") {
            dispatch(prepareVerticalBarChartData());
        } else if (chartType === "horizontalBar") {
            dispatch(prepareHorizontalBarChartData());
        } else if (chartType === "worldMap") {
            dispatch(prepareWorldMapChartData());
        } else {
            throw new TypeError(`Unexpected chartType value: ${chartType}`);
        }
    };
}
