/**
 * Returns data for this year only
 *
 * @param {Object} yData
 * @param {number} year - the year selected by timeSlider
 * @param {Array} countries
 * @returns {Array}
 */
export default function correlateDataWorldMapChart(yData, year, countries) {
    return (
        Object.entries(yData)
            // filter through years to find current year
            .filter(([dataSetYear]) => Number(dataSetYear) === year)
            .map(([dataSetYear, yearData]) =>
                Object.keys(yearData).reduce(
                    (correlatedData, countryISO2Code) => {
                        const [currentCountry] = countries.filter(
                            (country) =>
                                country.countryISO2Code === countryISO2Code
                        );

                        if (!currentCountry) {
                            return correlatedData;
                        }

                        const { countryId, nameDE, regionId } = currentCountry;

                        return [
                            ...correlatedData,
                            {
                                year: dataSetYear,
                                value: yData[dataSetYear][countryISO2Code],
                                countryISO2Code,
                                countryId,
                                regionId,
                                countryLabel: nameDE,
                            },
                        ];
                    },
                    []
                )
            )
            .reduce((result, yearDataset) => [...result, ...yearDataset], [])
    );
}
