/**
 * Returns the styling for each feature (country in GeoJSON)
 *
 * @param {Object} feature
 * @returns {Object}
 */

import getBorderColorForCountry from "./getColorForCountry";
import { rangeColor } from "../../constants";

const shadeAmount = 5;
const defaultFillColor = "grey";

export function calculateRangeArray(data) {
    const ranges = [];

    if (!data) {
        return ranges;
    }

    const countryValues = Object.values(data.selectedCountries)
        .filter((sc) => typeof sc !== "string")
        .map((value) => value.value);
    // get minValue and maxValue
    const minValue = Math.min(...countryValues);
    const maxValue = Math.max(...countryValues);
    const range = maxValue - minValue;
    const rangeFifth = range / shadeAmount;

    for (let i = 0; i < shadeAmount; i++) {
        const startModification = i * rangeFifth;
        const stopModification = (i + 1) * rangeFifth;
        // round with 2 digits
        const rangeStartRaw = (minValue + startModification) * 100;
        const rangeStopRaw = (minValue + stopModification) * 100;
        const rangeStart = Math.round(rangeStartRaw) / 100;
        const rangeStop = Math.round(rangeStopRaw) / 100;

        if (Number.isNaN(rangeStop)) {
            ranges.push([0, 0]);
        } else {
            ranges.push([rangeStart, rangeStop]);
            // "- 1" and "+ 1" so we don't have the problem that a value doesn't show up in the legend
            // ignoring prettier and eslint "no-mixed-operators"
            // prettier-ignore
            ranges[0][0] = ((ranges[0][0] * 100) - 1) / 100;
            // prettier-ignore
            ranges[ranges.length - 1][1] = ((ranges[ranges.length - 1][1] * 100) + 1) / 100;
        }
    }

    return ranges;
}

export default function styleFeature(feature, data) {
    const ranges = calculateRangeArray(data);
    // borderColor = Regioncolor
    let borderColor;
    let featureDataset;
    const featureCountryCode = feature.properties.A3;
    const selectedCountries = data.selectedCountries;
    const datasets = data.datasets;
    const countryIdsArray = Object.values(selectedCountries).map((country) =>
        typeof country === "string" ? country : country.countryId
    );

    if (!borderColor && countryIdsArray.includes(featureCountryCode)) {
        featureDataset = datasets[featureCountryCode];
        if (featureDataset) {
            borderColor = datasets[featureCountryCode].borderColor;
        } else {
            borderColor = getBorderColorForCountry(featureCountryCode, data);
        }
    }

    // fillColor is the defaultFillColor or 5 shades of pink, depending on value
    let fillColor = defaultFillColor;

    for (let i = 0; i < ranges.length; i++) {
        const rangeStop = ranges[i][1];

        if (featureDataset && featureDataset.value <= rangeStop) {
            fillColor = rangeColor[i];
            break;
        }
    }

    //

    return {
        fillColor,
        fill: true,
        weight: 2,
        color: borderColor,
        fillOpacity: 0.5,
        ranges,
    };
}
