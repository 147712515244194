import { mergeState } from "../../context.actions";
import filterCategories from "../../../helpers/dataSource/filterCategories";
import groupCategories from "../../../helpers/dataSource/groupCategories";

const context = "dataSource";
const matchLastIdPortion = /\.[^.]+$/g;

export default function selectCat({ id = "" } = {}) {
    return (dispatch, getState) => {
        const { categories } = getState();
        const breadcrumbs = [{ id: "", title: "Alle" }];
        const groupedCategories = groupCategories(
            filterCategories({
                categories,
                catId: id,
                includeAlternatives: true,
            })
        );
        let subCats = categories.filter(
            (d) => d.id.replace(matchLastIdPortion, "") === id
        )[0];

        subCats = subCats ? subCats.subCategories : [];

        breadcrumbs.push(...filterCategories({ categories, catId: id }));

        dispatch(
            mergeState({
                context,
                state: {
                    categories: groupedCategories,
                    subCategories: subCats,
                    breadcrumbs,
                },
            })
        );
    };
}
