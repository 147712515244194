import PropTypes from "prop-types";
import React from "react";
import Button from "react-mdl/lib/Button";

DataFilterButton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
};

export default function DataFilterButton({
    children,
    onClick = Function.prototype,
}) {
    return (
        <Button raised ripple onClick={onClick}>
            {children}
        </Button>
    );
}
