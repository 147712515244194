import { mergeState } from "../../context.actions";

const context = "timeSlider";

export default function stopAutoPlay() {
    return (dispatch, getState) => {
        const { autoPlayInterval } = getState().context[context];

        clearInterval(autoPlayInterval);

        dispatch(
            mergeState({
                context,
                state: {
                    isRunning: false,
                    autoPlayInterval: null,
                },
            })
        );
    };
}
