import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import store from "./store";
import Routes from "./routes";

export default function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Routes />
            </BrowserRouter>
        </Provider>
    );
}
