/* eslint-disable no-invalid-this */
import Chart from "chart.js";

function digits(number) {
    return parseInt(number).toString().length;
}

export function labels(min) {
    const minDigits = digits(Math.round(min));

    return function labelValue(value) {
        if (minDigits >= 6) {
            value = parseInt(value / Math.pow(10, minDigits));
        }

        return new Intl.NumberFormat().format(value);
    };
}

function tooltipTitleCallback(value) {
    const [{ datasetIndex, index: dataItemIndex }] = value;
    const dataItem = this._data.datasets[datasetIndex].data[dataItemIndex];
    const regionLabel = this._data.datasets[datasetIndex].regionNameDE;

    return `${dataItem.nameDE}, ${regionLabel.split("(")[0]}`;
}

function tooltipLabelCallback(value) {
    return [
        `${this._data.yTitle}: ${new Intl.NumberFormat().format(value.yLabel)}`,
        `${this._data.xTitle}: ${value.xLabel}`,
    ];
}

function logarithmicFormatter(tickValue, index, arr) {
    const me = this;
    const labelOpts = me.options.ticks.labels || {};
    const emptyTick = labelOpts.removeEmptyLines === true ? undefined : "";
    const remain =
        tickValue / Math.pow(10, Math.floor(Chart.helpers.log10(tickValue)));

    if (
        remain === 1 ||
        remain === 2 ||
        remain === 5 ||
        index === 0 ||
        index === arr.length - 1
    ) {
        return Number(tickValue).toLocaleString();
    }

    return emptyTick;
}

export function getDefaultChartOptions(
    yScaleTicks,
    xScaleTicks,
    yScale,
    xScale = "linear"
) {
    return {
        legend: false,
        animation: false,
        tooltips: {
            callbacks: {
                title: tooltipTitleCallback,
                label: tooltipLabelCallback,
            },
        },
        scales: {
            yAxes: [
                {
                    type: yScale === "log" ? "logarithmic" : "linear",
                    position: "left",
                    ticks: {
                        autoSkip: true,
                        labels: {
                            removeEmptyLines: true,
                        },
                        callback:
                            yScale === "log"
                                ? logarithmicFormatter
                                : labels(yScaleTicks.min),
                        ...yScaleTicks,
                    },
                },
            ],
            xAxes: [
                {
                    type: xScale === "log" ? "logarithmic" : "linear",
                    position: "bottom",
                    ticks: {
                        autoSkip: true,
                        ...xScaleTicks,
                    },
                },
            ],
        },
        layout: {
            padding: {
                left: 40,
                right: 40,
                top: 0,
                bottom: 40,
            },
        },
    };
}
