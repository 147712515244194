/* eslint-disable no-invalid-this */

import PropTypes from "prop-types";
import React from "react";
import equal from "fast-deep-equal";
import { Line } from "react-chartjs-2";
import { getDefaultChartOptions } from "../../helpers/chart/getDefaultChartOptions";
import ChartBackground from "../genericChart/ChartBackground";

function chartOptions(data, yScale, yScaleTicks, xScaleTicks) {
    const chartOptions = getDefaultChartOptions(
        yScaleTicks,
        xScaleTicks,
        yScale
    );
    const yValues = data.datasets.reduce((accumulator, dataset) => {
        const data = dataset.data;
        const y = data.map((d) => d.y);

        return accumulator.concat(y);
    }, []);
    const minY = Math.min(...yValues);
    const maxY = Math.max(...yValues);

    chartOptions.scales.yAxes[0].ticks.max = maxY;
    chartOptions.scales.yAxes[0].ticks.min = minY;

    chartOptions.scales.xAxes[0].ticks.stepSize = 10;
    chartOptions.scales.xAxes[0].ticks.min = xScaleTicks.minYear;
    chartOptions.scales.xAxes[0].ticks.max = xScaleTicks.maxYear;
    // http://www.chartjs.org/docs/latest/charts/line.html#disable-bezier-curves
    // is supposed to optimize performance when having a lot of data
    chartOptions.elements = {
        line: {
            tension: 0, // disables bezier curves
        },
    };
    chartOptions.hover = {
        onHover: (event, chartElement) => {
            event.target.style.cursor = chartElement[0] ? "pointer" : "default";
        },
    };

    // The chart should occupy as much space as possible
    chartOptions.maintainAspectRatio = false;

    return chartOptions;
}

class LineChart extends React.Component {
    render() {
        const { data, yScale, yScaleTicks, xScaleTicks } = this.props;
        const lastScaleAxisConfig = this.scaleAxisConfig;
        const scaleAxisConfig = [yScale, yScaleTicks, xScaleTicks];
        const redrawAxis =
            equal(lastScaleAxisConfig, scaleAxisConfig) === false;

        return (
            <div className="df-chart">
                <ChartBackground
                    currentYear={data === undefined ? null : data.year}
                />
                {data ? (
                    <Line
                        data={data}
                        redraw={redrawAxis}
                        options={chartOptions(
                            data,
                            yScale,
                            yScaleTicks,
                            xScaleTicks
                        )}
                    />
                ) : null}
            </div>
        );
    }
}

LineChart.propTypes = {
    data: PropTypes.object,
    yScale: PropTypes.string,
    yScaleTicks: PropTypes.object,
    xScaleTicks: PropTypes.object,
};

export default LineChart;
