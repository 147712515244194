/* eslint-disable no-invalid-this */

import PropTypes from "prop-types";
import React from "react";
import { Map, TileLayer, GeoJSON, ScaleControl } from "react-leaflet";
import WorldMapLegend from "./WoldMapLegend";
import styleFeature, {
    calculateRangeArray,
} from "../../helpers/worldMap/styleFeatures";
import getBorderColorForCountry from "../../helpers/worldMap/getColorForCountry";
import { datafoveaBlue, locationFlensburg, rangeColor } from "../../constants";
import ChartBackground from "../genericChart/ChartBackground";

const mapCenter = locationFlensburg;
const zoomLevel = 1;
const minZoom = 2;

function createNoValueLabel(featureCountryId) {
    return `Für ${featureCountryId} kein Wert verfügbar.`;
}

function createPopupHtml({ countryLabel, regionLabel, yTitle, value, year }) {
    return `<div>${year}</div><div>${countryLabel}, ${regionLabel}</div><div>${yTitle}: ${value}</div>`;
}

function openPopup(layer, popupProps) {
    const popup = layer.getPopup();
    const popupText = createPopupHtml(popupProps);

    if (popup) {
        popup.setContent(popupText);
        popup.openOn(layer);
    } else {
        const popupOptions = {
            autoPan: false,
        };

        layer.bindPopup(popupText, popupOptions).openPopup();
    }
}

function getPopupPropsNoValue(featureCountryId, data) {
    return {
        countryLabel: createNoValueLabel(featureCountryId),
        regionLabel: " ",
        yTitle: data.yTitle,
        value: "-",
        year: data.year,
    };
}

function getPopupPropsForValue(currentYearValue, data) {
    return {
        countryLabel: currentYearValue.countryLabel,
        regionLabel: currentYearValue.regionLabel,
        yTitle: data.yTitle,
        value: currentYearValue.value.toLocaleString(),
        year: data.year,
    };
}

function buildPopup(layer, feature, data) {
    const featureCountryId = feature.properties.A3;
    const currentYearValue = data.datasets[featureCountryId];
    let popupProps;

    // if no value is there (currentYearValue) and country is not in selectedCountries
    if (
        currentYearValue === undefined &&
        data.selectedCountries.find(
            (country) => country.countryId === featureCountryId
        ) === undefined
    ) {
        popupProps = getPopupPropsNoValue(featureCountryId, data);
    } else if (
        data.selectedCountries.find(
            (country) => country.countryId === featureCountryId
        )
    ) {
        popupProps = getPopupPropsForValue(currentYearValue, data);
    }

    if (popupProps) {
        openPopup(layer, popupProps);
    }
}

function highlightFeature(event, feature, data) {
    const layer = event.target;

    // set styles
    layer.setStyle({
        color: datafoveaBlue,
        weight: 3,
    });
    buildPopup(layer, feature, data);
}

function resetHighlight(event, feature, data) {
    const layer = event.target;
    const layerStyle = layer.defaultOptions.style(layer.feature);
    let color = layerStyle.color;
    const selectedFeatureCountry = data.selectedCountries.find(
        (country) => country.countryId === feature.properties.A3
    );
    const countryIsSelectedButNoValue = data.selectedCountries.find(
        (country) => country === feature.properties.A3
    );

    // if country is selected and has value, draw borderColor (regionColor) after hover
    if (selectedFeatureCountry) {
        color = data.datasets[feature.properties.A3].borderColor;
    } else if (countryIsSelectedButNoValue) {
        // if country is selected doesn't have value, draw borderColor (regionColor) after hover
        color = getBorderColorForCountry(feature.properties.A3, data);
    } else {
        color = "transparent";
    }

    // reset styles
    layer.setStyle({
        color,
        weight: layerStyle.weight,
    });
}

class WorldMapChart extends React.Component {
    render() {
        const { data, onFeatureClick } = this.props;
        const rangeData = calculateRangeArray(data);
        const that = this;

        function onFeatureClickHandler(e, feature, data) {
            onFeatureClick(feature.properties.A3);
            highlightFeature(e, feature, data);
        }

        function onEachFeature(feature, layer) {
            layer.on({
                click: (e) =>
                    onFeatureClickHandler(e, feature, that.props.data),
                mouseover: (e) => highlightFeature(e, feature, that.props.data),
                mouseout: (e) => resetHighlight(e, feature, that.props.data),
            });
        }

        function getStyleforFeature(feature) {
            return styleFeature(feature, data);
        }

        return (
            <div className="df-chart">
                <ChartBackground
                    currentYear={data === undefined ? null : data.year}
                />
                {data ? (
                    <Map
                        className="map"
                        center={mapCenter}
                        zoom={zoomLevel}
                        minZoom={minZoom}
                    >
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright" >OpenStreetMap</a> contributors'
                            target="_blank"
                            rel="noopener"
                            // colored map
                            url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png"
                        />
                        <GeoJSON
                            data={data.countryGeoJson}
                            style={getStyleforFeature}
                            onEachFeature={onEachFeature}
                            ref={(geoJson) => (this.geoJson = geoJson)}
                        />
                        <ScaleControl />
                        <WorldMapLegend
                            colors={rangeColor}
                            data={rangeData}
                            year={data.year}
                        />
                    </Map>
                ) : null}
            </div>
        );
    }

    componentDidUpdate() {
        if (this.geoJson && this.geoJson.layerContainer) {
            const data = this.props.data;

            this.geoJson.layerContainer.eachLayer((layer) => {
                if (layer.getPopup()) {
                    buildPopup(layer, layer.feature, data);
                }
            }, this);
        }
    }
}

WorldMapChart.propTypes = {
    data: PropTypes.object,
    onFeatureClick: PropTypes.func,
};

export default WorldMapChart;
