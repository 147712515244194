import PropTypes from "prop-types";
import React from "react";
import FABButton from "react-mdl/lib/FABButton";
import Icon from "react-mdl/lib/Icon";

AutoPlayButton.propTypes = {
    onStart: PropTypes.func,
    onStop: PropTypes.func,
    isRunning: PropTypes.bool,
    isDisabled: PropTypes.bool,
};

function AutoPlayButton({
    onStart = Function.prototype,
    onStop = Function.prototype,
    isRunning = false,
    isDisabled = true,
}) {
    const icon = isRunning ? "pause" : "play_arrow";
    const clickHandler = isRunning ? onStop : onStart;

    return (
        <FABButton onClick={clickHandler} disabled={isDisabled}>
            <Icon name={icon} />
        </FABButton>
    );
}

export default AutoPlayButton;
