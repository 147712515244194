import PropTypes from "prop-types";
import React from "react";

SlideList.propTypes = {
    children: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.element)),
    activeList: PropTypes.number,
};

function SlideList({ children, activeList = 0 }) {
    const sliderStyles = {
        transform: `translate(-${(activeList - 1) * 100}%)`,
    };

    sliderStyles.WebkitTransform = sliderStyles.transform;

    return (
        <div className="df-slide-list">
            <div className="df-slide-list__slider" style={sliderStyles}>
                {children}
            </div>
        </div>
    );
}

export default SlideList;
