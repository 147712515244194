export default function isDataSourceSelected(stateContext) {
    if (stateContext === undefined) {
        return false;
    }

    const chart = stateContext.chart;

    switch (chart.chartType) {
        case "bubble": {
            return (
                chart.yData !== null &&
                chart.xData !== null &&
                chart.zData !== null
            );
        }
        case "radar": {
            return chart.datas !== null && chart.datas.length > 2;
        }
        default: {
            return chart.yData !== null;
        }
    }
}
