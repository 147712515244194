/**
 * Returns a subset of yData (all data until year)
 *
 * @param {Object} yData
 * @param {number} year - the year selected by timeSlider
 * @returns {Array}
 */
export default function correlateDataLineChart(yData, year) {
    return Object.entries(yData)
        .filter(([dataSetYear]) => dataSetYear <= year)
        .map(([dataSetYear, yearData]) =>
            Object.keys(yearData)
                .filter((countryISO2Code) => yearData[countryISO2Code])
                .reduce(
                    (correlatedData, countryISO2Code) => [
                        ...correlatedData,
                        {
                            x: dataSetYear,
                            y: yData[dataSetYear][countryISO2Code],
                            countryISO2Code,
                        },
                    ],
                    []
                )
        )
        .reduce((result, yearDataset) => [...result, ...yearDataset], []);
}
