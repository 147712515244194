import hexToRgba from "hex-rgba";

export default function getBorderColorForCountry(countryId, data) {
    const regionsArr = Object.values(data.regions);
    // only look for GEO regions
    const geoRegionsArr = regionsArr.filter(
        (region) => region.type.toUpperCase() === "GEO"
    );
    // look for specific region
    const currentRegion = geoRegionsArr.find((region) =>
        region.countries.includes(countryId)
    );

    // transform hex to rgba
    return hexToRgba(currentRegion.color, 0.8 * 100);
}
