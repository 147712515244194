export default function scaleRadiusDataBubbleChart(
    radiusData,
    newMin = 3,
    newMax = 200
) {
    const radiusDataKeys = Object.keys(radiusData);
    const oldRadiuses = radiusDataKeys.map(
        (countryISO2Code) => radiusData[countryISO2Code]
    );
    const maxOld = Math.max(...oldRadiuses);
    const minOld = Math.min(...oldRadiuses);
    const diffOld = maxOld - minOld;
    const diffNew = newMax - newMin;

    return radiusDataKeys.reduce((scaledRadiusData, radiusDataKey) => {
        const radius = radiusData[radiusDataKey];
        const diff = radius - minOld;
        const ratio = diff / diffOld;

        scaledRadiusData[radiusDataKey] = newMin + ratio * diffNew;

        return scaledRadiusData;
    }, {});
}
