import { initState } from "../../context.actions";

const context = "dataSource";
const initialState = {
    modalOpen: false,
    axis: undefined,
    categories: undefined,
    subCategories: undefined,
    breadcrumbs: undefined,
    dataSources: undefined,
    selectedDataSourceIds: undefined,
};

export default function initDataSourceContext() {
    return (dispatch) => {
        dispatch(
            initState({
                context,
                state: initialState,
            })
        );
    };
}
