import PropTypes from "prop-types";
import React from "react";
import {
    List,
    ListItem,
    ListItemAction,
    ListItemContent,
} from "react-mdl/lib/List";
import Button from "react-mdl/lib/Button";

DataSourceCatList.propTypes = {
    onCatSelect: PropTypes.func,
    items: PropTypes.array,
};

function DataSourceCatList({ onCatSelect = Function.prototype, items = [] }) {
    return (
        <List>
            {items.map((item) => (
                <ListItem key={item.id} onClick={() => onCatSelect(item)}>
                    <ListItemContent>
                        <Button raised ripple>
                            {item.title}
                        </Button>
                    </ListItemContent>
                    <ListItemAction>
                        <i className="material-icons">keyboard_arrow_right</i>
                    </ListItemAction>
                </ListItem>
            ))}
        </List>
    );
}

export default DataSourceCatList;
