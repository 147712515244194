import PropTypes from "prop-types";
import React from "react";
import Button from "react-mdl/lib/Button";

DataSourceButton.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    vertical: PropTypes.bool,
};

function DataSourceButton({ label, onClick, vertical = false }) {
    return (
        <Button
            className={`df-data-source-button ${
                vertical ? "df-data-source-button--vertical" : ""
            }`}
            raised
            ripple
            onClick={onClick}
        >
            {vertical ? <span>{label}</span> : label}
        </Button>
    );
}

export default DataSourceButton;
