import PropTypes from "prop-types";
import React from "react";

Breadcrumbs.propTypes = {
    children: PropTypes.array,
    onCatSelect: PropTypes.func,
};

function Breadcrumbs({ children = [], onCatSelect = Function.prototype }) {
    return (
        <div className="df-breadcrumbs">
            {children.map((item) => (
                <button key={item.id} onClick={() => onCatSelect(item)}>
                    {item.title}
                </button>
            ))}
        </div>
    );
}

export default Breadcrumbs;
