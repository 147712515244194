import PropTypes from "prop-types";
import React from "react";
import { List, ListItem, ListItemContent } from "react-mdl/lib/List";
import Tooltip from "./Tooltip";

DataFilterRegionList.propTypes = {
    onSelectRegion: PropTypes.func,
    regionGroups: PropTypes.array,
};

export default function DataFilterRegionList({
    onSelectRegion = Function.prototype,
    regionGroups = [],
}) {
    return (
        <ul className="df-data-filter-region-list">
            {regionGroups.map((regionGroup) => (
                <li
                    key={regionGroup.groupId}
                    className="df-data-filter-region-list-item"
                >
                    <h2 className="df-data-filter-region-list-headline">
                        {regionGroup.nameDE}
                    </h2>
                    <List>
                        {regionGroup.regions.map((region) => (
                            <ListItem
                                key={region.regionId}
                                onClick={() => onSelectRegion(region.regionId)}
                            >
                                <Tooltip
                                    text={
                                        region.nameDE.includes("(")
                                            ? region.nameDE
                                            : null
                                    }
                                >
                                    <ListItemContent>
                                        {region.nameDE.split("(")[0]}
                                    </ListItemContent>
                                </Tooltip>
                            </ListItem>
                        ))}
                    </List>
                </li>
            ))}
        </ul>
    );
}
