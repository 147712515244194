import dataSetRangeFromYear from "./dataSetRangeFromYear";

/**
 * Calculate min/max values per dataSet.
 * Applies only on the dataSet starting with startYear to keep chart readable.
 *
 * @param {Object} dataSet
 * @param {Int} startYear
 * @returns {{min: number, max: number}}
 */
export default function determineScaleTickRange(dataSet, startYear) {
    const values = [];
    const dataSetForRange = dataSetRangeFromYear(dataSet, startYear);

    dataSetForRange.forEach((entry) => {
        values.push(...Object.values(entry));
    });

    const min = Math.min(...values);
    const max = Math.max(...values);

    return {
        min,
        max,
        minNotZero: Math.min(...values.filter((v) => v > 0)),
        crossesZero: min < 0,
    };
}
