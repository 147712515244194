import PropTypes from "prop-types";
import React from "react";
import Slider from "react-slick";
import MultilineButton from "../common/MultilineButton";
import { isGeoRegion } from "../../helpers/dataFilter/regions";

const matchBracketPart = / *\(.*\) */;

function removeBracketPartFromLabel(label) {
    return label.replace(matchBracketPart, "");
}

DataFilterRegionSelector.propTypes = {
    regions: PropTypes.array,
    onSelectRegion: PropTypes.func,
};

export default function DataFilterRegionSelector({
    regions = [],
    onSelectRegion = Function.prototype,
}) {
    const settings = {
        variableWidth: true,
        infinite: false,
        slidesToScroll: 2,
        slidesToShow: 4,
    };

    return (
        <div className="df-data-filter-region-selector">
            {regions.length > 0 && (
                <Slider {...settings} className="slider">
                    {regions.map((region) => (
                        <div
                            key={region.regionId}
                            className="df-data-filter-region-selector__button"
                        >
                            <MultilineButton
                                title={region.nameDE}
                                raised
                                ripple
                                onClick={() => onSelectRegion(region.regionId)}
                                className={
                                    isGeoRegion(region)
                                        ? "df-data-filter-region-selector__colored-button"
                                        : ""
                                }
                            >
                                {removeBracketPartFromLabel(region.nameDE)}
                                {isGeoRegion(region) && (
                                    <div
                                        className="df-data-filter-region-selector__color"
                                        style={{
                                            backgroundColor: region.color,
                                        }}
                                    />
                                )}
                            </MultilineButton>
                        </div>
                    ))}
                </Slider>
            )}
        </div>
    );
}
