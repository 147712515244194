import { connect } from "react-redux";
import Modal from "../views/common/Modal";
import closeImpressumModal from "../actions/context/impressum/closeImpressumModal";
import DataFilterButton from "../views/dataFilter/DataFilterButton";
import Impressum from "../views/common/Impressum";

const ConnectedImpressumCancelButton = connect(
    (state) => ({
        label: "Schließen",
        raised: true,
        ripple: true,
    }),
    {
        onClick: closeImpressumModal,
    }
)(DataFilterButton);
const ConnectedImpressum = connect((state) => ({
    ImpressumCancelButton: ConnectedImpressumCancelButton,
}))(Impressum);

export default connect(
    (state) => {
        const modalOpen = state.context.impressum
            ? state.context.impressum.modalOpen
            : false;

        return {
            className: "df-impressum-modal",
            open: modalOpen,
            title: "Impressum",
            View: modalOpen ? ConnectedImpressum : null,
        };
    },
    {
        onCancel: closeImpressumModal,
    }
)(Modal);
