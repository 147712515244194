import hexToRgba from "hex-rgba";

/**
 * Formats data required by Leaflet
 * Adds color information for each country based on region
 *
 * @param {Object} args
 * @returns {Array} formatted country data
 */
function transformIntoLeafletFormat({ datasets, regionMap }) {
    const result = {};
    const data = datasets
        .filter((country) => country.regionId !== "NA")
        .map((country) => {
            const { color, nameDE: regionLabel } = regionMap[country.regionId];

            return {
                ...country,
                borderColor: hexToRgba(color, 0.8 * 100),
                regionLabel,
            };
        });

    data.forEach((el) => {
        result[el.countryId] = { ...el };
    });

    return result;
}

export default transformIntoLeafletFormat;
