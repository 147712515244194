import { mergeState } from "../../context.actions";
import prepareWorldMapChartData from "../worldMapChart/prepareDataWorldMapChart";

const context = "dataFilter";

export default function toggleCountrySelectionWorldMapChart(countryId) {
    return (dispatch, getState) => {
        const { countryIds } = getState().context[context];

        dispatch(
            mergeState({
                context,
                state: {
                    countryIds: countryIds.includes(countryId)
                        ? countryIds.filter((id) => id !== countryId)
                        : countryIds.concat(countryId),
                },
            })
        );

        dispatch(prepareWorldMapChartData());
    };
}
