export default function correlateDataBubbleChart(
    xData,
    yData,
    radiusData = {}
) {
    return Object.keys(xData).reduce((correlatedData, countryISO2Code) => {
        const x = xData[countryISO2Code];
        const y = yData[countryISO2Code];
        const r = radiusData[countryISO2Code];

        if (x && y && r) {
            correlatedData.push({ x, y, r, countryISO2Code });
        }

        return correlatedData;
    }, []);
}
