import { connect } from "react-redux";
import BubblePage from "../views/bubble/BubblePage";
import BubbleChart from "../views/bubble/BubbleChart";

const ConnectedBubbleChart = connect((state) => ({
    data: state.context.chart.preparedData,
    xScaleRange: state.context.chart.xScaleRange,
    yScaleRange: state.context.chart.yScaleRange,
    xScale: state.context.chart.xScale,
    yScale: state.context.chart.yScale,
    xScaleTicks: state.context.chart.xScaleTicks,
    yScaleTicks: state.context.chart.yScaleTicks,
}))(BubbleChart);

export default connect((state) => ({
    BubbleChart: ConnectedBubbleChart,
}))(BubblePage);
