import { connect } from "react-redux";
import DataSourceComponent from "./DataSourceComponent";
import DataFilterComponent from "./DataFilterComponent";
import WorldMapPage from "../views/worldMapChart/WorldMapPage";
import WorldMapChart from "../views/worldMapChart/WorldMapChart";
import ToggleScaleButton from "../views/toggleScaleButton/ToggleScaleButton";
import toggleCountrySelectionWorldMapChart from "../actions/context/worldMapChart/toggleCountrySelectionWorldMapChart";
import selectScale from "../actions/context/chart/selectScale";

function onScaleChange(axis, scale) {
    return () => (dispatch) => {
        dispatch(selectScale({ axis, scale }));
    };
}

const ConnectedWorldMapChart = connect(
    (state) => ({
        data: state.context.chart.preparedData,
        yScaleRange: state.context.chart.yScaleRange,
        yScale: state.context.chart.yScale,
        yScaleTicks: state.context.chart.yScaleTicks,
    }),
    {
        onFeatureClick: toggleCountrySelectionWorldMapChart,
    }
)(WorldMapChart);
const ConnectedYToggleScaleButtonWorldMap = connect(
    (state) => ({
        scale: state.context.chart.yScale,
        // log doesn't make sense for world map
        logDisabled: true,
        vertical: true,
    }),
    {
        onLogSelect: onScaleChange("y", "log"),
        onLinSelect: onScaleChange("y", "lin"),
    }
)(ToggleScaleButton);

export default connect((state) => ({
    DataSourceComponent,
    DataFilterComponent,
    WorldMapChart: ConnectedWorldMapChart,
    YToggleScaleButtonWorldMap: ConnectedYToggleScaleButtonWorldMap,
}))(WorldMapPage);
