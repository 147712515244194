import { mergeState } from "../../context.actions";
import transformIntoChartjsFormat from "../../../helpers/chart/transformIntoChartjsFormat";
import correlateData from "../../../helpers/verticalBarChart/correlateDataVerticalBarChart";
import getMinYear from "../../../helpers/chart/getMinYear";

const context = "chart";

export default function prepareVerticalBarChartData() {
    return (dispatch, getState) => {
        const { selectedCountryIds, selectedRegionIds } =
            getState().context.dataFilter;
        const { yData, year, xDataSource, yDataSource, chartType } =
            getState().context[context];

        if (!year || !yData) {
            return;
        }
        const updatedYear =
            year > Math.max(...Object.keys(yData))
                ? Math.max(...Object.keys(yData))
                : year;

        const datasets = correlateData(yData, updatedYear);
        const preparedData = {
            labels: Object.keys(yData),
            datasets: transformIntoChartjsFormat({
                datasets,
                selectedCountryIds,
                selectedRegionIds,
                chartType,
            }),
            year: updatedYear,
            xTitle: xDataSource ? xDataSource.title : "",
            yTitle: yDataSource ? yDataSource.title : "",
        };

        dispatch(
            mergeState({
                context,
                state: {
                    preparedData,
                },
            })
        );
    };
}
