import PropTypes from "prop-types";
import React from "react";
import Grid from "react-mdl/lib/Grid/Grid";
import Button from "react-mdl/lib/Button";

Footer.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default function Footer({ label, onClick }) {
    return (
        <Grid className="df-chart-footer">
            <Button raised ripple onClick={onClick}>
                {label}
            </Button>
        </Grid>
    );
}
